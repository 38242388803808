class Utils {

    getBasicTableParams(location, exception = []) {
        const URLParams = new URLSearchParams(location.search);
        const params = [];
        ["page", "column", "sort", "query", "dateFrom", "dateTo"].filter((param) => exception.indexOf(param) === -1).forEach((param) => {
            const foundURLParam = URLParams.get(param);
            if (foundURLParam) params.push(param + "=" + foundURLParam);
        });
        let start = "?";
        if(exception.length > 0) start = "&";
        return params.length > 0 ? (start + params.join("&")) : "";
    }

    sortNumeric(arr, prop) {
        return arr.sort((a, b) => a[prop]-b[prop]);
    }

}

export default new Utils();