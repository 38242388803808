import OrderHistoryTable from "components/Orders/OrderHistoryTable";
import React from "react";

import {
    Container
} from "reactstrap";

const OrderHistory = (props) => {
  return (
    <>
      <Container fluid>
        <OrderHistoryTable/>
      </Container>
    </>
  );
};

export default OrderHistory;
