import React, { useEffect, useState } from "react";

import {
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Badge,
} from "reactstrap";
import useStore from "store/store";
import { saveAs } from "file-saver";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { USERS_ADMIN_PATH, USERS_PATH } from "variables/constants";
import Transformer from "transformers/Transformer";
import { USER_ROLES_TEXT } from "variables/constants";
import { REGISTER_PATH } from "variables/constants";
import { Button } from "components/Buttons/Button";
import { USER_ROLES } from "variables/constants";
import { Paginator } from "components/Pagination/Paginator";
import { TableSearch } from "components/Tables/TableSearch";
import { TableHeaderSortable } from "components/Tables/TableHeaderSortable";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import Utils from "utils/Utils";
import { useLocation } from "react-router-dom";
import { UPLOAD_INVOICE_PATH } from "variables/constants";
import { GET_INVOICES_PATH } from "variables/constants";
import { DOWNLOAD_INVOICE_PATH } from "variables/constants";
import { DELETE_INVOICE_PATH } from "variables/constants";

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const InvoicesMaintenance = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const store = useStore();
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [totalOrderRows, setTotalOrderRows] = useState(0);
  const [userModal, setUserModal] = useState(false);
  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [viewInvoicesModal, setViewInvoicesModal] = useState(false);
  const [selectedUserInvoices, setSelectedUserInvoices] = useState([]);

  const { addToast } = useToasts();

  const onEditUserClick = (user) => {
    toggleUserModal();
    setEditingUser(user);
  };

  const getInvoicesForUser = (user) => {
    Blitzcrank.get(GET_INVOICES_PATH + "/" + user.id, (status, data) => {
      if (status === 200) {
        setSelectedUserInvoices(
          data.invoices.map((invoice) => {
            return {
              date: parseInt(invoice.split(".")[0]),
              file: invoice,
            };
          })
        );
      }
    });
  };

  const onViewInvoicesClick = (user) => {
    if (user) {
      getInvoicesForUser(user);
    }
    toggleViewInvoicesModal();
    setEditingUser(user);
  };

  const toggleUserModal = () => {
    const newModalState = !userModal;
    if (!newModalState) {
      setEditingUser(null);
      setInvoiceFileToUpload(null);
    }
    setUserModal(newModalState);
  };

  const toggleViewInvoicesModal = () => {
    const newModalState = !viewInvoicesModal;
    if (!newModalState) {
      setEditingUser(null);
      setSelectedUserInvoices([]);
    }
    setViewInvoicesModal(newModalState);
  };

  const uploadInvoice = () => {
    const body = new FormData();
    body.append("userId", editingUser.id);
    invoiceFileToUpload.forEach((file, i) => {
      body.append("file" + i, file);
    });

    Blitzcrank.post(UPLOAD_INVOICE_PATH, body, (filesStatus, filesData) => {
      if (filesStatus === 200) {
        toggleUserModal();
        addToast(t("toasts.InvoiceSavedSuccessfully"), {
          appearance: TOAST_TYPE.SUCCESS,
        });
      } else {
        addToast(t("toasts.InvoiceSavedError"), {
          appearance: TOAST_TYPE.ERROR,
        });
      }
    });
  };

  const onFileInvoiceSelected = (e) => {
    setInvoiceFileToUpload([e.target.files[0]]);
  };

  useEffect(() => {
    if (store.isUserLogged) {
      Blitzcrank.get(
        USERS_PATH + Utils.getBasicTableParams(location),
        (status, data) => {
          if (status === 200) {
            setUsers(Transformer.parse(data.rows));
            setTotalOrderRows(data.totalRows);
          }
        }
      );
    }
  }, [store.isUserLogged, location]);

  const downloadInvoice = (invoiceId) => {
    Blitzcrank.get(
      DOWNLOAD_INVOICE_PATH + "/" + editingUser.id + "/" + invoiceId,
      (status, data) => {
        if (status === 200) {
          const blob = new Blob([data], { type: "application/pdf" });
          saveAs(blob, invoiceId);
        }
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      }
    );
  };

  const deleteInvoice = (invoiceId) => {
    if (window.confirm(t("toasts.ActionCantBeUndone"))) {
      Blitzcrank.post(
        DELETE_INVOICE_PATH,
        {
          userId: editingUser.id,
          invoiceId: invoiceId,
        },
        (status, data) => {
          if (status === 200) {
            getInvoicesForUser(editingUser);
            addToast(t("toasts.InvoiceDeletedSuccessfully"), {
              appearance: TOAST_TYPE.SUCCESS,
            });
          } else {
            addToast(t("toasts.InvoiceDeletedError"), {
              appearance: TOAST_TYPE.ERROR,
            });
          }
        }
      );
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" md="4">
            <TableSearch
              placeholder={t("common.Search") + " (" + t("common.Email") + ")"}
              endpoint={USERS_PATH}
            />
          </Col>
        </Row>

        <Table className="align-items-center" responsive>
          <TableHeaderSortable
            headers={[
              {
                text: t("common.ID"),
              },
              {
                text: t("common.Name"),
              },
              {
                text: t("common.Email"),
                sortable: true,
                id: "email",
              },
              {
                text: t("common.Role"),
              },
              {
                text: "",
              },
            ]}
          />
          <tbody>
            {users
              .filter((user) => user.role === 1)
              .map((user) => {
                return (
                  <tr key={"ukey_" + user.id}>
                    <th scope="row">
                      <span className="text-sm">{user.id}</span>
                    </th>
                    <th scope="row">
                      <span className="mb-0 text-sm">
                        {user.firstName} {user.lastName}
                      </span>
                    </th>
                    <td>{user.email}</td>
                    <td>
                      {USER_ROLES_TEXT[user.role]} ({user.role})
                    </td>
                    <td className="text-right">
                      <Button
                        icon="eye"
                        onClick={() => onViewInvoicesClick(user)}
                      />
                      <Button
                        color="primary"
                        icon="plus"
                        onClick={() => onEditUserClick(user)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        <Row>
          <Col>
            <Paginator totalRows={totalOrderRows} rightAligned />
          </Col>
        </Row>

        <Modal isOpen={userModal} toggle={toggleUserModal}>
          <ModalHeader toggle={toggleUserModal}>
            {t("common.Invoices")}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                File{" "}
                {["pdf"].map((format) => {
                  return (
                    <Badge className={"mr-1"} color="info">
                      {format}
                    </Badge>
                  );
                })}
              </Label>
              <Input
                onChange={onFileInvoiceSelected}
                accept={[".pdf"]}
                type="file"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={invoiceFileToUpload === null}
              color="primary"
              onClick={uploadInvoice}
            >
              {t("common.Upload")}
            </Button>{" "}
            <Button color="secondary" onClick={toggleUserModal}>
              {t("common.Cancel")}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          size="lg"
          isOpen={viewInvoicesModal}
          toggle={toggleViewInvoicesModal}
        >
          <ModalHeader toggle={toggleViewInvoicesModal}>
            {t("common.Invoices")}
          </ModalHeader>
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("common.PlacedOn")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedUserInvoices.length > 0 ? (
                  selectedUserInvoices.map((invoice) => {
                    return (
                      <tr>
                        <td>{invoice.file}</td>
                        <td>
                          {new Date(invoice.date).toLocaleString(
                            "en-GB",
                            dateOptions
                          )}
                        </td>
                        <td>
                          <div className="float-right">
                            <Button
                              onClick={() => downloadInvoice(invoice.file)}
                              size="sm"
                              color="primary"
                              icon="file-download"
                            />
                            <Button
                              onClick={() => deleteInvoice(invoice.file)}
                              size="sm"
                              color="danger"
                              icon="trash"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("common.UserHasNoInvoices")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleViewInvoicesModal}>
              {t("common.Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default InvoicesMaintenance;
