
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Buttons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import useSettings from "utils/Settings";

const FooterSocialLinks = (props) => {
    const { t } = useTranslation();
    const {socialLinks} = useSettings();
    
    return (
        <>
            {socialLinks && <div className={"footer-section footer-social-links " + (props.hasOwnProperty("isDarkBackground") && "white-text")}>
                <h4>{t("nav.SocialLinks")}</h4>
                <ul className="unstyled-list">
                    {socialLinks.facebook && <li><a title="Facebook" href={socialLinks.facebook} target="_blank"><FontAwesomeIcon icon={["fab", "facebook-square"]} /></a></li>}
                    {socialLinks.instagram && <li><a title="Instagram" href={socialLinks.instagram} target="_blank"><FontAwesomeIcon icon={["fab", "instagram-square"]} /></a></li>}
                </ul>
            </div>}
        </>
    );
};

export default FooterSocialLinks;
