
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table, Row, Col } from "reactstrap";
import useStore from "store/store";
import Cookies from "utils/Cookies";
import { COOKIE_POLICY_URL } from "variables/constants";
import { COOKIE_CONSENT_TYPE } from "variables/constants";
import CookiePolicy from "views/CookiePolicy";

// reactstrap components

const CookiePreferancesPanel = (props) => {
    const { onCancel, onConfirm, showCancelButton, confirmText, optionsExpanded, visitorsEnabled } = props;

    const { t } = useTranslation();

    const store = useStore();
    const [optionsVisible, setOptionsVisible] = useState(optionsExpanded || false);
    const [visitorCookiesEnabled, setVisitorsCookiesEnabled] = useState(visitorsEnabled || store.visitorCookiesEnabled);
    const [cookiePolicyModal, setCookiePolicyModal] = useState(false);

    const toggleCookiePolicyModal = () => {
        setCookiePolicyModal(!cookiePolicyModal);
    }

    useEffect(() => {
        if (!visitorsEnabled) setVisitorsCookiesEnabled(store.visitorCookiesEnabled);
    }, [store.visitorCookiesEnabled])

    const toggleVisitorsCookies = () => {
        setVisitorsCookiesEnabled(!visitorCookiesEnabled);
    }

    const save = () => {
        store.updateCookieConsent(visitorCookiesEnabled ? COOKIE_CONSENT_TYPE.VISITORS : COOKIE_CONSENT_TYPE.STRICT);
        if (onConfirm) onConfirm();
    }

    const cancel = () => {
        if (onCancel) onCancel();
    }

    return (
        <>
            <Row>
                <Col>
                    <p>
                        {t("common.Cookies.Disclaimer")}
                    </p>

                    <Button onClick={toggleCookiePolicyModal} className="text-muted pl-0" color="link">{t("common.Cookies.PolicyLinkText")}</Button>

                    {optionsVisible && <Table className="cookie-pref-table mt-4">
                        <tbody>
                            <tr>
                                <td>{t("common.Cookies.Strict")}</td>
                                <td className="text-right"><Button size="sm" disabled={true}>{t("common.Cookies.AlwaysOn")}</Button></td>
                            </tr>
                            <tr>
                                <td>{t("common.Cookies.Visitors")}</td>
                                <td className="text-right"><Button size="sm" onClick={toggleVisitorsCookies} outline={!visitorCookiesEnabled} color={visitorCookiesEnabled ? "primary" : "default"}>{visitorCookiesEnabled ? t("common.Cookies.ON") : t("common.Cookies.OFF")}</Button></td>
                            </tr>
                        </tbody>
                    </Table>}
                </Col>
            </Row>


            <Row className="mt-4">
                <Col>
                    <Button onClick={save} color="primary">{optionsVisible ? (confirmText || t("common.Cookies.SaveAndAccept")) : t("common.Cookies.Accept")}</Button>
                    {!optionsVisible && <Button onClick={() => setOptionsVisible(true)} color="link">{t("common.Cookies.Manage")}</Button>}
                    {showCancelButton && <Button outline color="default" onClick={cancel}>{t("common.Cancel")}</Button>}
                </Col>
            </Row>

            <Modal size="xl" isOpen={cookiePolicyModal} toggle={toggleCookiePolicyModal}>
                <ModalHeader toggle={toggleCookiePolicyModal}>{t("nav.CookiePolicy")}</ModalHeader>
                <ModalBody>
                    <CookiePolicy />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleCookiePolicyModal}>{t("common.Close")}</Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default CookiePreferancesPanel;
