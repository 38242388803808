
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const FooterContactUs = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={"footer-section footer-contact-us " + (props.hasOwnProperty("isDarkBackground") && "white-text")}>
                <h4>{t("common.ContactUs")}</h4>

                <ul className="unstyled-list contacts-list">
                    <li><b>{t("personal.businessName")}</b></li>
                    <li><span className="text-muted">{t("common.Eik")}</span>: {t("personal.eik")}</li>
                    <li className="mt-3"><FontAwesomeIcon className="text-muted" icon="map-marker-alt" /> {t("personal.address")}</li>
                    <li><FontAwesomeIcon className="text-muted" icon="phone" /> {t("personal.phone")}</li>
                    <li><FontAwesomeIcon className="text-muted" icon="envelope" /> {t("personal.email")}</li>
                </ul>
            </div>
        </>
    );
};

export default FooterContactUs;
