import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { CONTACT_US_PATH, TOAST_TYPE, SITE_KEY } from "../../variables/constants";
import { useToasts } from 'react-toast-notifications';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactUsSchema } from "schemas/ValidationSchemas";
import useSettings from "utils/Settings";
import { FormWrapper } from "components/FormGroup/FormWrap";
import FormSubmit from "components/FormGroup/FormSubmit";
import FormInputController from "components/FormGroup/FormInputController";

export const ContactUs = (props) => {
    const Blitzcrank = useBlitzcrank();
    const { validations } = useSettings();

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const formContactUs = useForm({
        resolver: yupResolver(ContactUsSchema(t, validations))
    });

    const submitForm = (data) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                Blitzcrank.post(CONTACT_US_PATH, {
                    ...data,
                    token: token
                }, (status) => {
                    if (status === 200) {
                        addToast(t("toasts.EmailSent"), { appearance: TOAST_TYPE.SUCCESS });
                        formContactUs.reset();
                    } else {
                        addToast(t("toasts.EmailSentError"), { appearance: TOAST_TYPE.ERROR });
                    }
                })
            });
        });
    }

    return <>
        <FormWrapper form={formContactUs}>
            <Row>
                <Col>
                    <FormInputController
                        type="text"
                        label={t("common.Email")}
                        id={"email"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputController
                        type="text"
                        label={t("common.Name")}
                        id={"name"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputController
                        type="tel"
                        label={t("common.PhoneNumber")}
                        id={"phone"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInputController
                        type="textarea"
                        label={t("common.Questions")}
                        id={"text"}
                    />
                </Col>
            </Row>

            <Row className={props.hasOwnProperty("centered") ? "text-center" : ""}>
                <Col>
                    <FormSubmit onClick={submitForm} color="primary">{t("common.Send")}</FormSubmit>
                </Col>
            </Row>

        </FormWrapper>
    </>
}