import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardHeader,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
  Alert,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { useToasts } from 'react-toast-notifications';
import Transformer from "transformers/Transformer";
import { TOAST_TYPE } from "variables/constants";
import useStore from "store/store";
import { SHIPPING_ADDRESSES_PATH } from "variables/constants";
import { ORDERS_PATH } from "variables/constants";
import { ORDER_FILES_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import { PAYMENT_TYPE } from "variables/constants";
import { PAYMENT_TYPE_TEXT } from "variables/constants";
import usePricing from "utils/Pricing";
import useSettings from "utils/Settings";
import { ShippingAddressManager } from "components/ShippingAddresses/ShippingAddressManager";
import { yupResolver } from "@hookform/resolvers/yup";
import { ShippingAddressSchema } from "schemas/ValidationSchemas";
import { useForm } from "react-hook-form";
import { SHIPPING_TYPE } from "variables/constants";
import { SHIPPING_TYPE_TEXT } from "variables/constants";


const Checkout = (props) => {
  const Blitzcrank = useBlitzcrank();

  const store = useStore();
  const { validations } = useSettings();
  const { t } = useTranslation();
  const pricing = usePricing();
  const { deliveryCost, cashPaymentCost, freeDeliveryGoal } = useSettings();

  const { addToast } = useToasts();

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CASH);
  const [shippingType, setShippingType] = useState(SHIPPING_TYPE.DELIVERY);
  const [backendTotalPrice, setBackendTotalPrice] = useState(null);

  const formShippingAddress = useForm({
    resolver: yupResolver(ShippingAddressSchema(t, validations)),
    defaultValues: {
      receiver: "",
      phoneNumber: "",
      addressLine: "",
      postalCode: "",
      city: "",
      country: "",
      shippingAddressName: ""
    }
  });

  const order = {
    totalPrice: props.totalPrice,
    product: props.product,
    quantity: props.quantity,
    files: props.files,
    comment: props.comment
  }

  let finalDeliveryCost = deliveryCost;
  let discountedDeliveryCost = deliveryCost;

  if (freeDeliveryGoal && parseFloat(order.totalPrice) > freeDeliveryGoal) {
    discountedDeliveryCost = 0;
    finalDeliveryCost = 0;
  }

  if (shippingType === SHIPPING_TYPE.PICKUP) {
    finalDeliveryCost = 0;
  }

  const placeOrder = (totalPrice) => {
    const address = { ...selectedShippingAddress };
    let shippingAddress = {};

    if (shippingType === SHIPPING_TYPE.DELIVERY) {
      shippingAddress = {
        SA_postalCode: address.postalCode,
        SA_addressLine: address.addressLine,
        SA_city: address.city,
        SA_country: address.country,
        SA_receiver: address.receiver,
        SA_phoneNumber: address.phoneNumber
      };
    }

    Blitzcrank.post(ORDERS_PATH, {
      userId: store.currentUser.id,
      ...shippingAddress,
      productId: order.product.id,
      quantity: order.quantity,
      totalPrice: totalPrice || absoluteTotalPrice,
      comment: order.comment,
      paymentType: paymentType,
      shippingType: shippingType
    }, (status, data) => {
      if (status === 200) {
        if (data.totalPrice) {
          setBackendTotalPrice(data);
        } else {
          addToast(t("toasts.OrderPlaceSuccess"), { appearance: TOAST_TYPE.SUCCESS });
          const body = new FormData();
          body.append("orderId", data.data.orderNumber);
          order.files.forEach((file, i) => {
            body.append("file" + i, file);
          });

          Blitzcrank.post(ORDER_FILES_PATH, body, (filesStatus, filesData) => {
            if (filesStatus === 200) {
              if (props.onPlaceOrder && data.data && data.data.orderNumber) props.onPlaceOrder(data.data.orderNumber);
            }
          })
        }
      } else {
        addToast(t("toasts.OrderPlaceFailure"), { appearance: TOAST_TYPE.ERROR });
      }
    })
  }

  let additionalTaxes = 0;
  let hasPaymentCost = false;
  const cashPaymentCostInValue = cashPaymentCost > 0 ? order.totalPrice*(cashPaymentCost/100) : 0;
  if(paymentType === PAYMENT_TYPE.CASH && shippingType === SHIPPING_TYPE.DELIVERY && cashPaymentCost > 0) {
    hasPaymentCost = true;
    additionalTaxes += cashPaymentCostInValue;
  }

  const absoluteTotalPrice = pricing.addAll([order.totalPrice, finalDeliveryCost, additionalTaxes])

  const addressIsRequired = shippingType === SHIPPING_TYPE.DELIVERY && selectedShippingAddress === null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="8" lg="6">
          <h2 className="text-muted mb-4">{t("common.DeliveryMethod")}</h2>
          <ListGroup>
            {Object.keys(SHIPPING_TYPE).map((stVal) => {
              const st = SHIPPING_TYPE[stVal];
              const deliveryCostDisclaimer = st === SHIPPING_TYPE.DELIVERY;
              return <ListGroupItem tag="button" action onClick={() => setShippingType(st)} active={st === shippingType}>
                {t(SHIPPING_TYPE_TEXT[st])} {deliveryCostDisclaimer && <Badge className="badge-in-clickable-list-group" color="info">+{pricing.format(discountedDeliveryCost)}</Badge>}
              </ListGroupItem>
            })}
          </ListGroup>
        </Col>
      </Row>
      {shippingType === SHIPPING_TYPE.DELIVERY && <Row className="justify-content-center">
        <Col md="8" lg="6">
          <h2 className="text-muted mt-4 mb-4">{t("common.ShippingAddress")}</h2>
          <ShippingAddressManager
            onSelected={(address) => setSelectedShippingAddress(address)}
            selectable={true} formShippingAddress={formShippingAddress}
          />
        </Col>
      </Row>}
      {shippingType === SHIPPING_TYPE.PICKUP && <Row className="justify-content-center">
        <Col md="8" lg="6">
          <h2 className="text-muted mt-4 mb-4">{t("common.PickUpFromLabAddress")}</h2>

          <ul className="unstyled-list">
            <li><b>{t("personal.businessName")}</b></li>
            <li className="mt-3">{t("personal.address")}</li>
          </ul>
        </Col>
      </Row>}
      {order && <Row className="justify-content-center">
        <Col md="8" lg="6">
          <h2 className="text-muted mt-4 mb-4">{t("common.PaymentType")}</h2>
          <ListGroup>
            {Object.keys(PAYMENT_TYPE).map((paymentKey) => {
              const paymentTypeId = PAYMENT_TYPE[paymentKey];
              const isCashPayment = paymentTypeId === PAYMENT_TYPE.CASH;
              const isActive = paymentType === paymentTypeId;
              return <ListGroupItem tag="button" action onClick={() => setPaymentType(paymentTypeId)} active={isActive}>{t(PAYMENT_TYPE_TEXT[paymentTypeId])} {hasPaymentCost && isCashPayment && <Badge className="badge-in-clickable-list-group" color="info">+{pricing.format(cashPaymentCostInValue)}</Badge>}</ListGroupItem>
            })}
          </ListGroup>
        </Col>
      </Row>}
      {order && <Row className="justify-content-center">
        <Col md="8" lg="6">
          <h2 className="text-muted mt-4 mb-4">{t("common.OrderReview")}</h2>
          <Card>
            <CardBody>
              <Table responsive className="total-price-table">
                <thead>
                  <tr>
                    <th colSpan="2">{t("common.UploadedFiles")}</th>
                  </tr>
                </thead>
                <tbody>
                  {order.files.map((file) => {
                    return <tr><td colSpan="2">{file.name}</td></tr>
                  })}
                </tbody>
                <thead>
                  <tr>
                    <th>{t("common.Product")}</th>
                    <th>{t("common.Price")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{order.quantity}x {order.product.title}</td>
                    <td><strong>{pricing.format(order.totalPrice)}</strong></td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>{t("common.Taxes")}</th>
                    <th>{t("common.Price")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("common.DeliveryCost")}</td>
                    <td><strong className={finalDeliveryCost === 0 && "text-green"}>{pricing.format(finalDeliveryCost)}</strong></td>
                  </tr>
                  {hasPaymentCost && <tr>
                    <td>{t("common.CashPaymentCost")}</td>
                    <td><strong>{pricing.format(cashPaymentCostInValue)}</strong> ({cashPaymentCost}%)</td>
                  </tr>}
                </tbody>
                <thead>
                  <tr className="total-row">
                    <th>{t("common.TotalPrice")}: </th>
                    <th><strong>{pricing.format(absoluteTotalPrice)}</strong></th>
                  </tr>
                </thead>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>}
      {addressIsRequired && <Row className="mt-4 justify-content-center">
        <Col md="8" lg="6" className="text-center">
          <Alert color="warning">{t("common.SelectShippingAddressAlert")}</Alert>
        </Col>
      </Row>}
      <Row className="mt-4 justify-content-center">
        <Col md="8" lg="6" className="text-center">
          <Button size="lg" color="primary" disabled={addressIsRequired} onClick={() => placeOrder()}>{t("nav.PlaceOrder")}</Button>
          <Button outline color="default" onClick={props.onCancelOrder}>{t("common.Cancel")}</Button>
        </Col>
      </Row>

      {backendTotalPrice && <Modal isOpen={true} toggle={() => setBackendTotalPrice(null)}>
        <ModalHeader>
          {t("common.TotalPriceHasChangedTitle")}
        </ModalHeader>
        <ModalBody>
          <p>{t("common.TotalPriceHasChangedDisclaimer")}</p>
          <p>{t("common.Taxes")}: <strong>{pricing.format(backendTotalPrice.taxes)}</strong></p>
          <p>{t("common.TotalPrice")}: <strong>{pricing.format(backendTotalPrice.totalPrice)}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            setBackendTotalPrice(null);
            placeOrder(backendTotalPrice.totalPrice)
          }}>{t("nav.PlaceOrder")}</Button>{' '}
          <Button color="secondary" onClick={() => setBackendTotalPrice(null)}>{t("common.Cancel")}</Button>
        </ModalFooter>
      </Modal>}
    </>
  );
};

export default Checkout;
