import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { saveAs } from 'file-saver'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import { TOAST_TYPE, USERS_BUSINESS_PATH } from "variables/constants";
import { USERS_PATH } from "variables/constants";
import ChangePassword from "./ChangePassword";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfileSchema } from "schemas/ValidationSchemas";
import useSettings from "utils/Settings";
import FormInputController from "components/FormGroup/FormInputController";
import { FormWrapper } from "components/FormGroup/FormWrap";
import FormSubmit from "components/FormGroup/FormSubmit";
import { ShippingAddressSchema } from "schemas/ValidationSchemas";
import { ShippingAddressManager } from "components/ShippingAddresses/ShippingAddressManager";
import { COUNTRY_LIST } from "variables/countries";
import { BusinessProfileSchema } from "schemas/ValidationSchemas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_INVOICES_PATH } from "variables/constants";
import { DOWNLOAD_INVOICE_PATH } from "variables/constants";

// core components

const UserInvoices = () => {
    const Blitzcrank = useBlitzcrank();
    const { t } = useTranslation();
    const store = useStore();
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        if (store.currentUser) {
            Blitzcrank.get(USERS_PATH + "/" + store.currentUser.id, (status, data) => {
                if (status === 200) {
                    Blitzcrank.get(GET_INVOICES_PATH + "/" + store.currentUser.id, (status, data) => {
                        if (status === 200) {
                            setInvoices(data.invoices.map((invoice) => {
                                return {
                                    date: parseInt(invoice.split(".")[0]),
                                    file: invoice
                                }
                            }))
                        }
                    })
                }
            });
        }
    }, [store.currentUser]);

    const downloadInvoice = (invoiceId) => {
        Blitzcrank.get(DOWNLOAD_INVOICE_PATH + "/" + store.currentUser.id + "/" + invoiceId, (status, data) => {
            if (status === 200) {
                const blob = new Blob([data], { type: 'application/pdf' })
                saveAs(blob, invoiceId)
            }
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        })
    }

    return (
        <>
            {/* Page content */}
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="order-xl-1" lg="8" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{t("nav.Invoices")}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {<CardBody>
                                <ListGroup>
                                    {invoices.length > 0 ? invoices.map((invoice) => <ListGroupItem
                                        href={`#`}
                                        title={"Download Invoice"}
                                        tag="a"
                                        onClick={() => downloadInvoice(invoice.file)}
                                    >
                                        <FontAwesomeIcon className="mr-1" icon="file-download" /> <strong>{invoice.file}</strong> <small>{new Date(invoice.date).toLocaleDateString()}</small>
                                    </ListGroupItem>) : <ListGroupItem>
                                            {t("common.NoInvoices")}
                                        </ListGroupItem>}
                                </ListGroup>
                            </CardBody>}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default UserInvoices;
