import React from "react";

import {
    Container
} from "reactstrap";

import PrivacyPolicy from "./PrivacyPolicy";

const UserPrivacyPolicy = (props) => {
    return (<>
        <Container fluid>
            <PrivacyPolicy />
        </Container>
    </>);
};

export default UserPrivacyPolicy;
