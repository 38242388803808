import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Buttons/Button";
import FormInputController from "components/FormGroup/FormInputController";
import FormSubmit from "components/FormGroup/FormSubmit";
import { FormWrapper } from "components/FormGroup/FormWrap";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import {
    Col,
    Container, FormGroup, Input, Label, Row
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { PasswordsSchema } from "schemas/ValidationSchemas";
import useStore from "store/store";
import useSettings from "utils/Settings";
import { TOAST_TYPE } from "variables/constants";
import { CHANGE_PASSWORD_PATH } from "variables/constants";

const ChangePassword = (props) => {
    const Blitzcrank = useBlitzcrank();

    const store = useStore();
    const { validations } = useSettings();

    const { t } = useTranslation();
    const { addToast } = useToasts();


    const formPasswords = useForm({
        resolver: yupResolver(PasswordsSchema(t, validations)),
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        }
    });

    const { watch } = formPasswords;

    const newPassword = watch("newPassword");
    const confirmNewPassword = watch("confirmNewPassword");

    const passwordsMatch = newPassword === confirmNewPassword;
    const matchRequired = newPassword.length > 0 && confirmNewPassword.length > 0;

    const changePassword = (data) => {
        Blitzcrank.post(CHANGE_PASSWORD_PATH, {
            userId: store.currentUser.id,
            oldPassword: data.currentPassword,
            newPassword: data.confirmNewPassword
        }, (status, data) => {
            if (status === 200) {
                addToast(t("toasts.ChangePasswordSuccess", { appearance: TOAST_TYPE.SUCCESS }));
                if (props.onSubmit) props.onSubmit();
            } else {
                addToast(t("toasts.ChangePasswordFailure"), { appearance: TOAST_TYPE.ERROR });
            }
        })
    }

    return (
        <>
            <FormWrapper form={formPasswords}>
                <Row>
                    <Col>
                        <FormInputController
                            type="password"
                            label={t("auth.CurrentPassword")}
                            id={"currentPassword"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInputController
                            type="password"
                            label={t("auth.NewPassword")}
                            id={"newPassword"}
                            invalid={confirmNewPassword && newPassword !== confirmNewPassword}
                            valid={confirmNewPassword && newPassword === confirmNewPassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInputController
                            type="password"
                            label={t("auth.ConfirmNewPassword")}
                            id={"confirmNewPassword"}
                            invalid={confirmNewPassword && newPassword !== confirmNewPassword}
                            valid={confirmNewPassword && newPassword === confirmNewPassword}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {matchRequired && <div className="text-muted font-italic">
                            <small>
                                {t("auth.PasswordsAre")}{" "}
                                {passwordsMatch ?
                                    <span className="text-success font-weight-700">{t("auth.Matching")}</span> :
                                    <span className="text-danger font-weight-700">{t("auth.NotMatching")}</span>}
                            </small>
                        </div>}
                    </Col>
                </Row>

                <div className="mt-4">
                    <FormSubmit color="primary" disabled={!passwordsMatch} onClick={changePassword}>{t("common.Confirm")}</FormSubmit>
                    <Button color="default" outline onClick={props.onCancel}>{t("common.Cancel")}</Button>
                </div>
            </FormWrapper>
        </>
    );
};

export default ChangePassword;
