import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Container,
    CardBody,
    Card
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUs } from "components/ContactUs/ContactUs";
import { useTranslation } from "react-i18next";
import GoogleMap from "components/GoogleMap/GoogleMap";

export const Contacts = (props) => {

    const { t } = useTranslation();

    return <>
        <Container fluid>
            <Row className="justify-content-center">
                <Col xl="6" lg="8" md="12" className="pt-2">
                    <Card style={{height:"100%"}}>
                        <CardBody>
                            <ul className="unstyled-list contacts-list">
                                <li><b>{t("personal.businessName")}</b></li>
                                <li><span className="text-muted">{t("common.Eik")}</span>: {t("personal.eik")}</li>
                                <li className="mt-3"><FontAwesomeIcon className="text-muted" icon="map-marker-alt" /> {t("personal.address")}</li>
                                <li><FontAwesomeIcon className="text-muted" icon="phone" /> {t("personal.phone")}</li>
                                <li><FontAwesomeIcon className="text-muted" icon="envelope" /> {t("personal.email")}</li>
                            </ul>
                            <hr />
                            <ContactUs />
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6" lg="8" md="12" className="pt-2">
                    <Card style={{height:"100%"}}>
                        <CardBody>
                            <GoogleMap/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>




    </>
}