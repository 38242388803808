import * as yup from "yup";

const defaultRequiredText = "validations.FieldIsRequired";


export const PasswordResetSchema = (t) => {
    let schema = {
        email: yup.string().required(t(defaultRequiredText)).email(t("validations.InvalidEmail"))
    };

    return yup.object().shape(schema);
}

export const UserProfileSchema = (t, validations, withoutAuth) => {
    let schema = {
        firstName: yup.string().required(t(defaultRequiredText)),
        lastName: yup.string().required(t(defaultRequiredText)),
        email: yup.string().required(t(defaultRequiredText)).email(t("validations.InvalidEmail")),
        phoneNumber: yup.string().required(t(defaultRequiredText)).matches(validations.phoneNumber, t("validations.InvalidPhoneNumber")),
        password: yup.string().required(t(defaultRequiredText)).matches(validations.password, t("validations.InvalidPassword")),
        repeatedPassword: yup.string().required(t(defaultRequiredText))
    };

    if (withoutAuth) {
        delete schema.email;
        delete schema.password;
        delete schema.repeatedPassword;
    }

    return yup.object().shape(schema);
}

export const BusinessProfileSchema = (t, validations) => {
    const schema = {
        businessName: yup.string().nullable().required(t(defaultRequiredText)),
        bulstat: yup.string().nullable().required(t(defaultRequiredText)),
        mol: yup.string().nullable().required(t(defaultRequiredText))
    }
    return yup.object().shape(schema);
}

export const PasswordsSchema = (t, validations) => {
    const schema = {
        currentPassword: yup.string().required(t(defaultRequiredText)),
        newPassword: yup.string().required(t(defaultRequiredText)).matches(validations.password, t("validations.InvalidPassword")),
        confirmNewPassword: yup.string().required(t(defaultRequiredText))
    }
    return yup.object().shape(schema);
}

export const PasswordsChangeSchema = (t, validations) => {
    const schema = {
        newPassword: yup.string().required(t(defaultRequiredText)).matches(validations.password, t("validations.InvalidPassword")),
        confirmNewPassword: yup.string().required(t(defaultRequiredText))
    }
    return yup.object().shape(schema);
}

export const ShippingAddressSchema = (t, validations) => {
    let schema = {
        shippingAddressName: yup.string().required(t(defaultRequiredText)),
        receiver: yup.string().required(t(defaultRequiredText)),
        phoneNumber: yup.string().required(t(defaultRequiredText)).matches(validations.phoneNumber, t("validations.InvalidPhoneNumber")),
        addressLine: yup.string().nullable().required(t(defaultRequiredText)),
        city: yup.string().nullable().required(t(defaultRequiredText)),
        country: yup.string().nullable().required(t(defaultRequiredText)),
        postalCode: yup.string().nullable().required(t(defaultRequiredText)),
    };

    return yup.object().shape(schema);
}

export const ContactUsSchema = (t, validations) => {
    const schema = {
        name: yup.string().required(t(defaultRequiredText)),
        email: yup.string().required(t(defaultRequiredText)).email(t("validations.InvalidEmail")),
        //phone: yup.string().required(t(defaultRequiredText)),
        text: yup.string().required(t(defaultRequiredText))
    };
    return yup.object().shape(schema);
}