import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Buttons/Button";
import React, { useEffect, useState } from "react";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  Label,
  FormText,
  Card,
  CardBody
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import Transformer from "transformers/Transformer";
import { PRODUCT_IMAGE_UPLOAD_PATH } from "variables/constants";
import { APP_URL } from "variables/constants";
import { PRODUCTS_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import useStore from "store/store";
import { INPUT_CURRENCY } from "variables/constants";
import { PricingWrapper } from "utils/Pricing";
import usePricing from "utils/Pricing";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import Utils from "utils/Utils";


const ProductsMaintenance = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const pricing = usePricing();
  const { addToast } = useToasts();
  const [products, setProducts] = useState([]);
  const [newProductModal, toggleAddNewProductModal] = useState(false);
  const defaultNewProduct = {
    title: "",
    price: "",
    description: ""
  };
  const [newProduct, setNewProduct] = useState(defaultNewProduct);
  const [updateProduct, setUpdateProduct] = useState(defaultNewProduct);
  const [updateProductModal, toggleUpdateProductModal] = useState(false);
  const [uploadPhotoModal, setUploadPhotoModal] = useState(false);
  const [photoForUpload, setPhotoForUplaod] = useState(null);


  const toggleUploadPhotoModal = () => {
    const newState = !uploadPhotoModal;
    if (!newState) {
      setUpdateProduct(defaultNewProduct);
      setPhotoForUplaod(null);
    }
    setUploadPhotoModal(newState);
  }

  useEffect(() => {
    Blitzcrank.get(PRODUCTS_PATH, (status, data) => {
      setProducts(Transformer.parse(data.products));
    })
  }, [])

  const addNewProduct = () => {
    toggleAddNewProductModal(false);
    newProduct.orderInList = parseInt(newProduct.orderInList) || 0;
    Blitzcrank.post(PRODUCTS_PATH, newProduct, (putStatus, putData) => {
      if (putStatus === 200) {
        addToast(t("toasts.ProductCreateSuccess"), { appearance: TOAST_TYPE.SUCCESS });
        setNewProduct(defaultNewProduct);
        Blitzcrank.get(PRODUCTS_PATH, (getStatus, getData) => {
          if (getStatus === 200) setProducts(Transformer.parse(getData.products));
        })
      } else {
        addToast(t("toasts.ProductCreateFailure"), { appearance: TOAST_TYPE.ERROR });
      }

    })
  }

  const updateProductAction = () => {
    toggleUpdateProductModal(false);
    updateProduct.orderInList = parseInt(updateProduct.orderInList) || 0;
    Blitzcrank.update(PRODUCTS_PATH, updateProduct, (putStatus, putData) => {
      if (putStatus === 200) {
        addToast(t("toasts.ProductUpdateSuccess"), { appearance: TOAST_TYPE.SUCCESS });
        setUpdateProduct(defaultNewProduct);
        Blitzcrank.get(PRODUCTS_PATH, (getStatus, getData) => {
          if (getStatus === 200) {
            setProducts(Transformer.parse(getData.products));
          }
        })
      } else {
        addToast(t("toasts.ProductUpdateFailure"), { appearance: TOAST_TYPE.ERROR });
      }


    })
  }

  const onNewProductFieldUpdate = (field, value) => {
    let product = { ...newProduct };
    product[field] = value;
    setNewProduct(product);
  }

  const onUpdateProductField = (field, value) => {
    let product = { ...updateProduct };
    product[field] = value;
    setUpdateProduct(product);
  }

  const deleteProduct = (id) => {
    if (window.confirm(t("toasts.ActionCantBeUndone"))) {
      Blitzcrank.delete(PRODUCTS_PATH + "/" + id, (delStatus, delData) => {
        if (delStatus === 200) {
          addToast(t("toasts.ProductDeleteSuccess"), { appearance: TOAST_TYPE.SUCCESS });
          Blitzcrank.get(PRODUCTS_PATH, (getStatus, getData) => {
            if (getStatus === 200) {
              setProducts(Transformer.parse(getData.products));
            }
          })
        } else {
          addToast(t("toasts.ProductDeleteFailure"), { appearance: TOAST_TYPE.ERROR });

        }

      })
    }
  }

  const toggleUpdateProduct = (product, modal) => {
    toggleUpdateProductModal(modal);
    setUpdateProduct(product);
  }

  const onProductImageClick = (product) => {
    toggleUploadPhotoModal()
    setUpdateProduct(product);
  }

  const uploadPhoto = () => {
    const body = new FormData();
    body.append("image", photoForUpload);
    body.append("productId", updateProduct.id);

    toggleUploadPhotoModal();

    Blitzcrank.post(PRODUCT_IMAGE_UPLOAD_PATH, body, (status, data) => {
      if (status === 200) {
        window.location.reload();
      } else {
        addToast(t("toasts.ProductPhotoUploadFailure"), { appearance: TOAST_TYPE.ERROR });
      }
    })
  }

  const onImageSelected = (e) => {
    setPhotoForUplaod(e.target.files[0]);
  }

  return (
    <>
      <Container fluid>
        <Table className="align-items-center" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">{t("common.Position")}</th>
              <th scope="col">{t("common.Image")}</th>
              <th scope="col">{t("common.Title")}</th>
              <th scope="col">{t("common.Price")}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {Utils.sortNumeric(products, "orderInList").map((product) => {
              return <tr>
                <td width="50px">
                  {product.orderInList}
                </td>
                <td>
                  <div onClick={() => onProductImageClick(product)} className="product-image-upload">
                    <img className="list-product-img" top alt="Product cap" src={APP_URL + product.image} />
                    <span className="text-overlay"><FontAwesomeIcon icon="plus" /> {t("common.Manage")}</span>
                  </div>
                </td>
                <td>
                  <span className="mb-0 text-sm">
                    {product.title}
                  </span>
                </td>
                <td>{pricing.format(product.price)}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#pbl"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href="#pbl"
                        onClick={(e) => toggleUpdateProduct(product, true)}
                      >
                        {t("common.Edit")}
                      </DropdownItem>
                      <DropdownItem
                        href="#pbl"
                        onClick={(e) => deleteProduct(product.id)}
                      >
                        {t("common.Delete")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            })}
          </tbody>
        </Table>

        <Modal
          className="modal-dialog-centered"
          isOpen={newProductModal}
          toggle={() => toggleAddNewProductModal(!newProductModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("common.NewProduct")}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleAddNewProductModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <Label>{t("common.ProductOrder")}</Label>
                <FormGroup>
                  <Input
                    id="newProductOrder"
                    placeholder={t("common.ProductOrder")}
                    type="number"
                    onChange={(e) => onNewProductFieldUpdate("orderInList", e.target.value)}
                    value={newProduct.orderInList}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Title")}</Label>
                <FormGroup>
                  <Input
                    id="newProductTitle"
                    placeholder={t("common.Title")}
                    type="text"
                    onChange={(e) => onNewProductFieldUpdate("title", e.target.value)}
                    value={newProduct.title}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Description")}</Label>
                <FormGroup>
                  <Input
                    id="newProductDescription"
                    placeholder={t("common.Description")}
                    type="text"
                    onChange={(e) => onNewProductFieldUpdate("description", e.target.value)}
                    value={newProduct.description}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Price")} ({INPUT_CURRENCY})</Label>
                <FormGroup>
                  <Input
                    id="newProductPrice"
                    placeholder={t("common.Price")}
                    type="text"
                    onChange={(e) => onNewProductFieldUpdate("price", e.target.value)}
                    value={newProduct.price}
                  />
                </FormGroup>
              </Col>
            </Row>

          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={addNewProduct}>
              {t("common.Save")}
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleAddNewProductModal(false)}
            >
              {t("common.Cancel")}
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={updateProductModal}
          toggle={() => toggleUpdateProductModal(!updateProductModal)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel2">
              {t("common.UpdateProduct")}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleUpdateProductModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <Label>{t("common.ProductOrder")}</Label>
                <FormGroup>
                  <Input
                    id="updateProductOrder"
                    placeholder={t("common.ProductOrder")}
                    type="number"
                    onChange={(e) => onUpdateProductField("orderInList", e.target.value)}
                    value={updateProduct.orderInList}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Title")}</Label>
                <FormGroup>
                  <Input
                    id="updateProductTitle"
                    placeholder={t("common.Title")}
                    type="text"
                    onChange={(e) => onUpdateProductField("title", e.target.value)}
                    value={updateProduct.title}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Description")}</Label>
                <FormGroup>
                  <Input
                    id="updateProductDescription"
                    placeholder={t("common.Description")}
                    type="text"
                    onChange={(e) => onUpdateProductField("description", e.target.value)}
                    value={updateProduct.description}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>{t("common.Price")} ({INPUT_CURRENCY})</Label>
                <FormGroup>
                  <Input
                    id="updateProductPrice"
                    placeholder={t("common.Price")}
                    type="text"
                    onChange={(e) => onUpdateProductField("price", e.target.value)}
                    value={updateProduct.price}
                  />
                </FormGroup>
              </Col>
            </Row>

          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={updateProductAction}>
              {t("common.Save")}
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleUpdateProductModal(false)}
            >
              {t("common.Cancel")}
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={uploadPhotoModal}
          toggle={toggleUploadPhotoModal}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel2">
              {t("common.UploadPhotoFor")} {updateProduct.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleUploadPhotoModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormGroup>
              <Label for="exampleFile">{t("common.Image")}</Label>
              <Input onChange={onImageSelected} accept=".jpg" type="file" />
              <FormText color="muted">
                Preferred format 250x250 JPG
              </FormText>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <Button color="primary" type="button" onClick={uploadPhoto}>
              {t("common.Save")}
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggleUploadPhotoModal}
            >
              {t("common.Cancel")}
            </Button>
          </div>
        </Modal>

        <Button color="primary" icon="plus" onClick={() => toggleAddNewProductModal(true)}>{t("common.CreateNewProduct")}</Button>
      </Container>
    </>
  );
};

export default ProductsMaintenance;
