import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "utils/Cookies";
import { COOKIE } from "variables/constants";

import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { APP_URL } from "variables/constants";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      crossDomain: true,
      loadPath: APP_URL + "/locales/{{lng}}/{{ns}}.json"
    },
    lng: Cookies.getCookie(COOKIE.LANG) || "bg", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;