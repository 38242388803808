import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Utils from 'utils/Utils';

/**
 * 
 * @param {totalRows} props - All pages length 
 * 
 * 
 */
export const Paginator = (props) => {

    const location = useLocation();
    const defaultPage = new URLSearchParams(location.search).get("page");
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(defaultPage || 1);
    const history = useHistory();
    const params = Utils.getBasicTableParams(location, ["page"]);

    const totalPages = Math.ceil(props.totalRows/10);
    let pagesArr = [];

    for (let i = 1; i <= totalPages; i++) {
        pagesArr.push(i);
    }

    if (totalPages > 9) {
        pagesArr = [activePage];
        if (activePage - 1 > 1) {
            pagesArr.unshift(activePage - 1);
        }
        if (activePage + 1 < totalPages) {
            pagesArr.push(activePage + 1);
        }
        if (activePage > 1) {
            if (activePage > 3) {
                pagesArr.unshift(0);
            }
            pagesArr.unshift(1);
        }
        if (activePage < totalPages) {
            if (activePage < totalPages - 2) {
                pagesArr.push(0);
            }
            pagesArr.push(totalPages);
        }
    }

    const onPageClick = (pageNumber) => {
        setActivePage(pageNumber);
        const fullQuery = "?page=" + pageNumber + params;
        history.push(fullQuery);
        timeoutLoading();
    }

    const timeoutLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    return <>
        {pagesArr.length > 1 && <Pagination className={props.rightAligned && "float-right"} size="sm" aria-label="Navigation">
            {activePage > 1 && <PaginationItem disabled={loading}>
                <PaginationLink onClick={() => onPageClick(activePage - 1)} previous />
            </PaginationItem>}
            {pagesArr.map((pageNumber) => {
                if (pageNumber === 0) {
                    return <PaginationItem disabled>
                        <PaginationLink>
                            ...
                        </PaginationLink>
                    </PaginationItem>;
                }

                return <PaginationItem disabled={loading} active={pageNumber === activePage}>
                    <PaginationLink onClick={() => onPageClick(pageNumber)}>
                        {pageNumber}
                    </PaginationLink>
                </PaginationItem>
            })}
            {activePage < totalPages && <PaginationItem disabled={loading}>
                <PaginationLink onClick={() => onPageClick(activePage + 1)} next />
            </PaginationItem>}
        </Pagination>}
    </>
}