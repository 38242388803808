import axios from "axios";
import React, { useEffect, useState } from "react";
import { COOKIE_POLICY_URL } from "variables/constants";


const CookiePolicyPlain = (props) => {
    const [html, setHTML] = useState("");

    useEffect(() => {
        fetchCookiePolicy()
    }, []);

    const fetchCookiePolicy = async () => {
        let promise = await axios.get(COOKIE_POLICY_URL);
        setHTML(promise.data);
    }
    
    return (<>
        <div dangerouslySetInnerHTML={{__html: html}}></div>
    </>);
};

export default CookiePolicyPlain;
