import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { StoreWrapper } from "./store/store";
import "i18n";
import App from "App.js";
import { ToastProvider } from 'react-toast-notifications';
import { CustomToast } from "components/Toast/CustomToast";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus, faTrash, faMapMarkerAlt, faEdit, faEye, faAngleDoubleUp, faUsers, faCoins, faList, faSave, faUndo, faUpload, faSearch, faCheckSquare, faSquare,
  faSort, faSortUp, faSortDown, faChevronRight, faChevronDown, faPhone, faEnvelope, faUser, faHistory, faTools, faFileDownload
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { PricingWrapper } from "utils/Pricing";
import { SettingsWrapper } from "utils/Settings";
import { SuspensePreloader } from "SuspensePreloader";
import { BlitzcrankWrapper } from "requests/BlitzcrankWrapper";
import ErrorBoundary from "ErrorBoundary";

library.add(faPlus, faMapMarkerAlt, faAngleDoubleUp, faCheckSquare, faFileDownload, faSquare, faTrash, faEdit, faEye, faUser, faUsers, faCoins, faList, faSave, faUndo, faUpload, faTools,
  faSearch, faSort, faSortUp, faSortDown, faChevronRight, faChevronDown, faPhone, faEnvelope, faFacebookSquare, faInstagramSquare, faHistory)

ReactDOM.render(
  <ErrorBoundary>
    <StoreWrapper>
      <BlitzcrankWrapper>
        <SettingsWrapper>
          <ToastProvider components={{ Toast: CustomToast }} autoDismiss={true} placement={"bottom-right"}>
            <Suspense fallback={<SuspensePreloader />}>
              <PricingWrapper>
                <App />
              </PricingWrapper>
            </Suspense>
          </ToastProvider>
        </SettingsWrapper>
      </BlitzcrankWrapper>
    </StoreWrapper >
  </ErrorBoundary>
  ,
  document.getElementById("root")
);
