import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Container
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUs } from "components/ContactUs/ContactUs";
import { useEffect, useState } from "react";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { SETTINGS_PATH } from "variables/constants";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { SAVE_SETTINGS_JSON } from "variables/constants";
import { Prompt } from "react-router-dom";

export const SettingsMaintenance = (props) => {

    const [json, setJson] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editPath, setEditPath] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    const Blitzcrank = useBlitzcrank();

    const settingsPath = "." + SETTINGS_PATH;
    const unsavedChangesText = "You have unsaved changes. Do you wish to continue?";

    useEffect(() => {
        Blitzcrank.get(settingsPath, (status, data) => {
            if (status === 200) {
                setEditPath(settingsPath);
                setHasChanges(false);
                setJson(data);
                setLoading(false);
            }
        });
    }, [settingsPath]);

    const handleChange = (newJson) => {
        setJson(newJson);
        setHasChanges(true);
    }

    const changeJson = (path) => {
        if (!hasChanges || window.confirm(unsavedChangesText)) {
            setLoading(true);
            setEditPath(path);
            Blitzcrank.get(path, (status, data) => {
                if (status === 200) {
                    setHasChanges(false);
                    setJson(data);
                    setLoading(false);
                }
            });
        }
    }

    const submitJson = () => {
        setLoading(true);
        Blitzcrank.post(SAVE_SETTINGS_JSON, {
            json: json,
            path: editPath
        }, (status, data) => {
            if (status === 200) {
                Blitzcrank.get(editPath, (getStatus, getData) => {
                    if (getStatus === 200) {
                        setHasChanges(false);
                        setJson(getData);
                        setLoading(false);
                    }
                });
            } else {
                setLoading(false);
            }
        });
    }

    const templates = [
        {
            text: "Settings",
            path: settingsPath
        },
        {
            text: "BG Translations",
            path: "./locales/bg/translation.json"
        },
        {
            text: "EN Translations",
            path: "./locales/en/translation.json"
        },
        {
            text: "Why-Choose-Us",
            path: "./public/why-choose-us/list.json"
        },
        {
            text: "Our-Services",
            path: "./public/our-services/list.json"
        },
        {
            text: "Homepage Banners",
            path: "./public/homepage-carousel/list.json"
        },
        {
            text: "Footer Banners",
            path: "./public/footer-banners/list.json"
        }
    ]

    return <>
        <Container fluid>
            <Prompt
                when={hasChanges}
                message={location =>
                    unsavedChangesText
                }
            />

            <Row>
                <Col lg="4" xs="6">
                    <Input value={editPath} onChange={(e) => changeJson(e.target.value)} type="select">
                        {templates.map((template) => {
                            return <option selected={editPath === template.path} value={template.path}>{template.text}</option>
                        })}
                    </Input>
                </Col>
                <Col lg="8" xs="6" className="text-right">
                    <Button outline color='default' onClick={() => changeJson(editPath)}>Revert</Button>
                    <Button color='primary' onClick={submitJson}>Save</Button>
                </Col>
            </Row>

            <h3 className="mt-4 mb-4">{editPath}</h3>
            {!loading && <Editor
                allowedModes={['form', 'tree']}
                mode="form"
                value={json}
                onChange={handleChange}
            />}
        </Container>
    </>
}