
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table } from "reactstrap";
import useStore from "store/store";
import Cookies from "utils/Cookies";
import { COOKIE_POLICY_URL } from "variables/constants";
import { COOKIE_CONSENT_TYPE } from "variables/constants";
import CookiePreferancesPanel from "./CookiePreferancesPanel";

// reactstrap components

const CookieSettingsController = (props) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <>
            <Button size="sm" onClick={toggleModal} color="link">{t("nav.CookieSettings")}</Button>
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader>
                    {t("nav.CookieSettings")}
                </ModalHeader>
                <ModalBody>
                    <CookiePreferancesPanel
                        optionsExpanded={true}
                        confirmText={t("common.Cookies.Save")}
                        showCancelButton={true}
                        onCancel={toggleModal}
                        onConfirm={toggleModal}
                    />
                </ModalBody>
            </Modal>
        </>

    );
};

export default CookieSettingsController;
