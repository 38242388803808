
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import PreFooterBanner from "components/Footers/PreFooterBanner";
import { BackToTopButton } from "components/Buttons/BackToTopButton";

const BrowseLayout = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === props.layoutPath) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content customer-layout" ref={mainContent}>
        <AuthNavbar sticky={true} smallerWhenSticked={true} />
        <div style={{paddingTop: "6.5rem"}}>
          <Switch>
            {getRoutes(routes)}
            {props.isSessionChecked && <Redirect from="*" to="/" />}
          </Switch>
        </div>

        <BackToTopButton/>
      </div>
      <AuthFooter />
    </>
  );
};

export default BrowseLayout;
