import Index from "views/Index.js";
import UserProfile from "views/UserProfile.js";
import Register from "views/Register.js";
import Login from "views/Login.js";

import ProductsMaintenance from "views/ProductsMaintenance.js";
import OrdersMaintenance from "views/OrdersMaintenance.js";
import UsersMaintenance from "views/UsersMaintenance.js";
import PageBuilder from "views/PageBuilder";
import Checkout from "views/Checkout";
import CreateOrder from "views/CreateOrder";
import CustomerDashboard from "views/CustomerDashboard";
import Homepage from "views/Homepage";
import Discounts from "views/Discounts";
import MaintainPrivacyPolicy from "views/MaintainPrivacyPolicy";
import UserPrivacyPolicy from "views/UserPrivacyPolicy";
import PasswordReset from "views/PasswordReset";
import ChangePassword from "views/ChangePassword";
import AuthChangePassword from "views/AuthChangePassword";
import OrderHistory from "views/OrderHistory";
import { Contacts } from "views/Contacts";
import { MyDiscounts } from "views/MyDiscounts";
import { OurServices } from "views/OurServices";
import CookiePolicy from "views/CookiePolicy";
import { SettingsMaintenance } from "views/SettingsMaintenance";
import InvoicesMaintenance from "views/InvoicesMaintenance";
import UserInvoices from "views/UserInvoices";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    translation: "nav.Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/index",
    name: "Your Dashboard",
    translation: "nav.Dashboard",
    icon: "ni ni-tv-2 text-primary",
    auth: true,
    component: CustomerDashboard,
    layout: "/public",
    showInSidebar: true
  },
  {
    path: "/productsMaintenance",
    name: "Product Maintenance",
    translation: "nav.ProductsMaintenance",
    icon: "ni ni-box-2 text-primary",
    component: ProductsMaintenance,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/ordersMaintenance",
    name: "Orders Maintenance",
    translation: "nav.OrdersMaintenance",
    icon: "ni ni-bullet-list-67 text-primary",
    component: OrdersMaintenance,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/usersMaintenance",
    name: "Users Maintenance",
    translation: "nav.Users",
    icon: "ni ni-circle-08 text-primary",
    component: UsersMaintenance,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/invoicesMaintenance",
    name: "Invoices Maintenance",
    translation: "nav.Invoices",
    icon: "ni ni-archive-2 text-primary",
    component: InvoicesMaintenance,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/discounts",
    name: "Discounts",
    translation: "nav.Discounts",
    icon: "ni ni-money-coins text-primary",
    component: Discounts,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/pageBuilder",
    name: "Page Builder",
    translation: "nav.PageBuilder",
    icon: "ni ni-ruler-pencil text-primary",
    component: PageBuilder,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/settingsMaintenance",
    name: "Settings Maintenance",
    translation: "nav.SettingsMaintenance",
    icon: "ni ni-ui-04 text-red",
    component: SettingsMaintenance,
    layout: "/admin",
    showInSidebar: true
  },
  {
    path: "/checkout",
    name: "Checkout",
    translation: "nav.Checkout",
    icon: "ni ni-single-02 text-yellow",
    auth: true,
    component: Checkout,
    layout: "/public",
  },
  {
    path: "/createOrder",
    name: "Create Order",
    translation: "nav.CreateOrder",
    icon: "ni ni-fat-add text-yellow",
    auth: true,
    component: CreateOrder,
    layout: "/public",
    showInSidebar: true
  },
  {
    path: "/orderHistory",
    name: "Order History",
    translation: "nav.OrderHistory",
    icon: "ni ni-bullet-list-67 text-primary",
    auth: true,
    component: OrderHistory,
    layout: "/public",
    showInSidebar: true
  },
  {
    path: "/ourservices",
    name: "Our Services",
    translation: "nav.OurServices",
    icon: "ni ni-app text-primary",
    component: OurServices,
    layout: "/public",
    dividedInSidebar: true,
    showInSidebar: true
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    translation: "nav.PrivacyPolicy",
    icon: "ni ni-lock-circle-open text-primary",
    component: MaintainPrivacyPolicy,
    layout: "/admin",
    dividedInSidebar: true,
    showInSidebar: true
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    translation: "nav.PrivacyPolicy",
    icon: "ni ni-lock-circle-open text-primary",
    component: UserPrivacyPolicy,
    layout: "/public",
    dividedInSidebar: true,
    showInSidebar: true
  },
  {
    path: "/discounts",
    name: "Discounts",
    translation: "nav.YourDiscounts",
    icon: "ni ni-money-coins text-primary",
    component: MyDiscounts,
    layout: "/public",
    showInSidebar: true
  },
  {
    path: "/user-profile",
    name: "Your Profile",
    translation: "nav.MyProfile",
    icon: "ni ni-single-02 text-yellow",
    component: UserProfile,
    showInSidebar: true,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "My Profile",
    translation: "nav.MyProfile",
    icon: "ni ni-single-02 text-primary",
    auth: true,
    component: UserProfile,
    showInSidebar: true,
    layout: "/public"
  },
  {
    path: "/user-invoices",
    name: "My Invoices",
    translation: "nav.MyInvoices",
    icon: "ni ni-archive-2 text-primary",
    auth: true,
    component: UserInvoices,
    showInSidebar: true,
    layout: "/public"
  },
  {
    path: "/contacts",
    name: "Contacts",
    translation: "nav.Contacts",
    icon: "ni ni-badge text-primary",
    component: Contacts,
    layout: "/public",
    showInSidebar: true
  },
  
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    translation: "nav.PrivacyPolicy",
    icon: "ni ni-lock-circle-open text-primary",
    component: UserPrivacyPolicy,
    layout: "/browse"
  },
  {
    path: "/cookiepolicy",
    name: "Cookie Policy",
    translation: "nav.CookiePolicy",
    icon: "ni ni-lock-circle-open text-primary",
    component: CookiePolicy,
    layout: "/browse"
  },
  {
    path: "/resetpassword/:token",
    name: "Change Password",
    translation: "nav.ChangePassword",
    icon: "ni ni-lock-circle-open text-primary",
    component: AuthChangePassword,
    layout: "/auth"
  },
  {
    path: "/home",
    name: "Home",
    translation: "nav.Home",
    icon: "ni ni-circle-08 text-pink",
    component: Homepage,
    showAsQuickLink: true,
    layout: ""
  },
  {
    path: "/login",
    name: "Login",
    translation: "nav.Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    showAsQuickLink: true,
    layout: "/auth",
  },
  {
    path: "/passwordreset",
    name: "Password Reset",
    translation: "nav.ResetPassword",
    icon: "ni ni-key-25 text-info",
    component: PasswordReset,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    translation: "nav.Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    showAsQuickLink: true,
    layout: "/auth"
  }
];
export default routes;
