
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import useStore from "store/store";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import {SITE_KEY, USER_SESSION_PATH} from "variables/constants";
import BrowseLayout from "layouts/Browse";
import ErrorView from "views/ErrorView";
import { LOGOUT_PATH } from "variables/constants";
import CookieConsentModal from "components/Cookies/CookieConsentModal";
import { Preloader } from "Preloader";
import Homepage from "views/Homepage";

const App = (props) => {
    const store = useStore();
    const [isSessionChecked, setSessionChecked] = useState(false);
    const Blitzcrank = useBlitzcrank();

    useEffect(() => {
        Blitzcrank.get(USER_SESSION_PATH, (status, data) => {
            if (status === 200) {
                store.loginUser(data.session)
            } else if (status === 403) {
                Blitzcrank.post(LOGOUT_PATH, {}, (status, data) => {
                    store.logoutUser();
                })
            } else {
                store.logoutUser();
            }
            setSessionChecked(true)
        })
    }, []);

    useEffect(() => {
        const loadScriptByURL = (id, url) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                document.body.appendChild(script);
            }

        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    }, []);

    let homePath = "/home";
    if (store.isUserLogged) homePath = "/public/index";
    if (store.isUserAdmin) homePath = "/admin/index";

    return (
        <BrowserRouter>
            {Blitzcrank.isLoading && <Preloader />}
            <CookieConsentModal showCookiePolicyModal={store.showCookiePolicyModal} />
            <Switch>
                {store.isUserLogged && store.isUserAdmin && <Route path="/admin" render={(props) => <AdminLayout layoutPath="/admin" isSessionChecked={isSessionChecked} {...props} />} />}
                {!store.isUserLogged && <Route path="/auth" render={(props) => <AuthLayout layoutPath="/auth" isSessionChecked={isSessionChecked} {...props} />} />}
                {store.isUserLogged && !store.isUserAdmin && <Route path="/public" render={(props) => <AdminLayout layoutPath="/public" isSessionChecked={isSessionChecked} isUserLogged={store.isUserLogged} {...props} />} />}
                {!store.isUserLogged && <Route path="/browse" render={(props) => <BrowseLayout layoutPath="/browse" isSessionChecked={isSessionChecked} {...props} />} />}
                {!store.isUserLogged && <Route path="/home" render={(props) => <BrowseLayout layoutPath="" isSessionChecked={isSessionChecked} {...props}>
                    <Homepage/>
                </BrowseLayout>} />}
                <Route path="/error" render={(props) => <ErrorView />} />
                {isSessionChecked && <Redirect from="/" to={homePath} />}
            </Switch>
        </BrowserRouter>
    )
}

export default App;
