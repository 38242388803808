import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Container,
    Table,
    Badge,
    Card,
    CardHeader,
    CardBody
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUs } from "components/ContactUs/ContactUs";
import { useEffect, useState } from "react";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { DISCOUNTED_PRODUCTS_PATH } from "variables/constants";
import Transformer from "transformers/Transformer";
import usePricing from "utils/Pricing";
import { useTranslation } from "react-i18next";

export const MyDiscounts = (props) => {
    const Blitzcrank = useBlitzcrank();

    const [products, setProducts] = useState([]);
    const { t } = useTranslation();
    const pricing = usePricing();


    useEffect(() => {
        Blitzcrank.get(DISCOUNTED_PRODUCTS_PATH, (status, data) => {
            if (status === 200) {
                const parsedData = Transformer.parse(data.products)
                setProducts(parsedData);
            }
        })
    }, [])

    return <>
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg="8" md="12">
                    <Card>
                        
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t("common.Product")}</th>
                                        <th>{t("common.Discount")}</th>
                                        <th>{t("common.Price")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product, i) => {

                                        return <tr key={"prd_" + i}>
                                            <td>{product.title}</td>
                                            <td><Badge style={{ fontSize: "1rem" }} color={product.discount && product.discount > 0 ? "success" : "info"}>{product.discount || 0}%</Badge></td>
                                            <td>
                                                {product.discount > 0 && <span className="text-through text-muted"><small>{pricing.format(product.price)}</small></span>}&nbsp;
                                                <span><b>{pricing.calculate(product.price, 1, product.discount)}</b></span>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Container>
    </>
}