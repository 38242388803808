import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Buttons/Button';
import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Container, Row, Col, CardBody, Card, Label, FormGroup } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import Utils from 'utils/Utils';

/**
 * 
 * 
 */
export const OrdersTableFilter = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const URLParams = new URLSearchParams(location.search);
    const defaultSearch = URLParams.get("query");
    const defaultDateFrom = URLParams.get("dateFrom");
    const defaultDateTo = URLParams.get("dateTo");

    const [search, setSearch] = useState(defaultSearch || "");
    const [dateFrom, setDateFrom] = useState(defaultDateFrom || "");
    const [dateTo, setDateTo] = useState(defaultDateTo || "");

    const history = useHistory();
    const params = Utils.getBasicTableParams(location, ["page", "query", "dateFrom", "dateTo"]);

    const getFilterParams = () => {
        return ["query=" + search, "dateFrom=" + dateFrom, "dateTo=" + dateTo].filter((prop) => !!prop.split("=")[1]).join("&");
    }

    const doFilter = () => {
        const fullQuery = "?" + getFilterParams() + params;
        history.push(fullQuery);
        timeoutLoading();
    }

    const clearFilter = () => {
        setSearch("");
        setDateFrom("");
        setDateTo("");
        history.push("?clear=true" + params);
    }

    const timeoutLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    return <>
        <Card className="mb-4">
            <CardBody>
                <Row className="mb-2">
                    <Col lg="4">
                        <FormGroup>
                            <Label>{t("common.SearchOrdersTableAdmin")}</Label>
                            <Input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t("common.Search")} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>{t("common.DateFrom")}</Label>
                            <Input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} placeholder={t("common.DateFrom")} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>{t("common.DateTo")}</Label>
                            <Input type="date" value={dateTo} onChange={(e) => setDateTo(e.target.value)} placeholder={t("common.DateTo")} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button disabled={loading} color="primary" onClick={doFilter}>{t("common.ApplyFilters")}</Button>
                        <Button disabled={loading} outline color="default" onClick={clearFilter}>{t("common.ClearFilters")}</Button>
                    </Col>
                </Row>

            </CardBody>

        </Card>

    </>
}