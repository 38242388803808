
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import { useToasts } from 'react-toast-notifications';
import { TOAST_TYPE } from "variables/constants";
import {LOGIN_PATH, USER_SESSION_PATH} from "variables/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Login = () => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const store = useStore();
  const {addToast} = useToasts();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  })

  const login = () => {
    Blitzcrank.post(LOGIN_PATH, {
      email: loginForm.email,
      password: loginForm.password
    }, (status, data, headers) => {
      if(status === 200) {
        Blitzcrank.get(USER_SESSION_PATH, (status, data) => {
          if(status === 200) {
            addToast(t("toasts.LoginSuccess"), {appearance: TOAST_TYPE.SUCCESS});
            store.loginUser(data.session)
          } else {
            addToast(t("toasts.LoginFailure"), {appearance: TOAST_TYPE.ERROR});
            store.logoutUser()
          }
        })
      } else {
        addToast(t("toasts.LoginFailure"), {appearance: TOAST_TYPE.ERROR});
      }
      
    })
  }

  const onInputChange = (prop, val) => {
    const newForm = {...loginForm};
    newForm[prop] = val;
    setLoginForm(newForm)
  }

  const onEnter = (e, callback) => {
    if(e.key === "Enter") callback();
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>{t("auth.SignInText")}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("common.Email")}
                    type="email"
                    name="username"
                    value={loginForm.email}
                    onChange={(e) => onInputChange("email", e.target.value)}
                    autocomplete="username"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t("common.Password")}
                    type="password"
                    name="password"
                    value={loginForm.password}
                    onKeyPress={(e) => onEnter(e, login)}
                    onChange={(e) => onInputChange("password", e.target.value)}
                    autocomplete="password"
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button disabled={!loginForm.password || !loginForm.email} onClick={login} className="my-4" color="primary" type="button">
                  {t("common.Login")}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Button
              color="link"
              className="text-light pt-0"
              tag={Link}
              to={"/auth/passwordreset"}
            >
              <small>{t("common.ForgottenPassword")}?</small>
            </Button>
          </Col>
          <Col className="text-right" xs="6">
            <Button
              color="link"
              className="text-light pt-0"
              tag={Link}
              to={"/auth/register"}
            >
              <small>{t("common.CreateNewAccount")}</small>
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
