
import { Button } from "components/Buttons/Button";
import { Paginator } from "components/Pagination/Paginator";
import { TableHeaderSortable } from "components/Tables/TableHeaderSortable";
import { TableSearch } from "components/Tables/TableSearch";
import React, { useEffect, useState } from "react";

import {
    Badge,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import Transformer from "transformers/Transformer";

import { ORDER_STATUS } from "variables/constants";

import { ORDER_STATUS_TEXT } from "variables/constants";
import { ORDERS_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import usePricing from "utils/Pricing";
import Utils from "utils/Utils";
import { useLocation } from "react-router-dom";
import { COUNTRY_LIST } from "variables/countries";
import { getCountryName } from "variables/countries";
import { SHIPPING_TYPE_TEXT } from "variables/constants";
import { SHIPPING_TYPE } from "variables/constants";
import { PAYMENT_TYPE_TEXT } from "variables/constants";


const OrderHistoryTable = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const pricing = usePricing();
    const store = useStore();
    const location = useLocation();
    const [orders, setOrders] = useState([]);
    const [detailsOrderModal, setDetailsOrderModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [totalOrderRows, setTotalOrderRows] = useState(0);

    const dateOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };

    const toggleDetailsOrderModal = () => {
        setDetailsOrderModal(!detailsOrderModal);
        if (detailsOrderModal === false) setSelectedOrder(null);
    }

    useEffect(() => {
        if (store.currentUser) {
            Blitzcrank.get(ORDERS_PATH + "/" + store.currentUser.id + Utils.getBasicTableParams(location), (status, data) => {
                if (status === 200) {
                    setOrders(Transformer.parse(data.rows));
                    setTotalOrderRows(data.totalRows);
                }
            });
        }
    }, [store.currentUser, location]);

    const onCheckOrderDetails = (order) => {
        setSelectedOrder(order);
        setDetailsOrderModal(true);
    }

    return (
        <>
            <Row>
                <Col lg="3" md="4">
                    <TableSearch
                        placeholder={t("common.Search") + " #"}
                        endpoint={ORDERS_PATH + "/" + store.currentUser.id}
                    />
                </Col>
            </Row>


            <Table responsive>
                <TableHeaderSortable
                    headers={[
                        {
                            text: "#",
                            id: "orderNumber",
                            sortable: true
                        },
                        {
                            text: t("common.PlacedOn"),
                            id: "placedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.OrderAcceptedOn"),
                            id: "acceptedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.OrderCompletedOn"),
                            id: "completedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.Status")
                        },
                        {
                            text: t("common.TotalPrice"),
                            id: "totalPrice",
                            sortable: true
                        },
                        {
                            text: ""
                        }
                    ]}
                />
                <tbody>
                    {orders.map((order) => {
                        let statusDotClassName = "bg-warning";
                        switch (order.status) {
                            case ORDER_STATUS.PENDING:
                                statusDotClassName = "bg-warning";
                                break;
                            case ORDER_STATUS.ACCEPTED:
                                statusDotClassName = "bg-info";
                                break;
                            case ORDER_STATUS.COMPLETED:
                                statusDotClassName = "bg-success";
                                break;
                            case ORDER_STATUS.CANCELLED:
                                statusDotClassName = "bg-danger";
                                break;
                            default:
                                statusDotClassName = "bg-warning";
                        }
                        return <tr>
                            <td>{order.orderNumber}</td>
                            <td>{new Date(order.placedOnDate).toLocaleString("en-GB",
                                dateOptions)}</td>
                            <td>{order.acceptedOnDate ? new Date(order.acceptedOnDate).toLocaleString("en-GB",
                                dateOptions) : t("common.OrderNotAccepted")}</td>
                            <td>{order.completedOnDate ? new Date(order.completedOnDate).toLocaleString("en-GB",
                                dateOptions) : t("common.OrderNotFinished")}</td>
                            <td>
                                <Badge color="" className="badge-dot mr-4">
                                    <i className={statusDotClassName} />
                                    {t(ORDER_STATUS_TEXT[order.status])}
                                </Badge>
                            </td>
                            <td>{pricing.format(order.totalPrice)}</td>
                            <td><Button onClick={() => onCheckOrderDetails(order)} size="sm" outline color="default" icon={"eye"}></Button></td>
                        </tr>
                    })}

                </tbody>
            </Table>

            <Row>
                <Col>
                    <Paginator
                        totalRows={totalOrderRows} rightAligned />
                </Col>
            </Row>

            {selectedOrder && <Modal isOpen={detailsOrderModal} toggle={toggleDetailsOrderModal}>
                <ModalHeader toggle={toggleDetailsOrderModal}>{t("common.Order")} #{selectedOrder.orderNumber}</ModalHeader>
                <ModalBody>
                    <span className="text-muted">{t("common.PlacedOn")}</span><br />
                    <span>{new Date(selectedOrder.placedOnDate).toLocaleString()}</span><br /><br />
                    
                    <span className="text-muted">{t("common.DeliveryMethod")}</span><br />
                    <span>{t(SHIPPING_TYPE_TEXT[selectedOrder.shippingType])}</span><br /><br />

                    <span className="text-muted">{t("common.ShippingAddress")}</span><br />

                    {selectedOrder.shippingType === SHIPPING_TYPE.PICKUP && <>
                    <span>{t("personal.address")}</span><br /><br />
                    </>}
                    
                    {selectedOrder.shippingType === SHIPPING_TYPE.DELIVERY && <>
                    
                    <span>{selectedOrder.sa_addressLine}</span><br />
                    <span>{selectedOrder.sa_city}, {selectedOrder.sa_postalCode}, {getCountryName(selectedOrder.sa_country)}</span><br />
                    <span>{selectedOrder.sa_phoneNumber}</span><br /><br />
                    </>}

                    <span className="text-muted">{t("common.Status")}</span><br />
                    <span>{t(ORDER_STATUS_TEXT[selectedOrder.status])}</span><br /><br />
                    <span className="text-muted">{t("common.Product")}</span><br />
                    <span>{selectedOrder.quantity}x {selectedOrder.productTitle}</span><br />
                    <span>{pricing.format(selectedOrder.totalPrice - selectedOrder.taxes)}</span><br /><br />
                    
                    <span className="text-muted">{t("common.OrderHistoryTaxes")}</span><br />
                    <span>{pricing.format(selectedOrder.taxes)}</span><br /><br />

                    <span className="text-muted">{t("common.TotalPrice")}</span><br />
                    <span>{pricing.format(selectedOrder.totalPrice)}</span><br /><br />

                    <span className="text-muted">{t("common.Author")}</span><br />
                    <span>{t("common.You")}</span><br /><br />
                    <span className="text-muted">{t("common.PaymentType")}</span><br />
                    <span>{t(PAYMENT_TYPE_TEXT[selectedOrder.paymentType])}</span><br /><br />
                    <span className="text-muted">{t("common.Comment")}</span><br />
                    <span>{selectedOrder.comment || t("common.None")}</span>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDetailsOrderModal}>{t("common.Cancel")}</Button>
                </ModalFooter>
            </Modal>}
        </>
    );
};

export default OrderHistoryTable;
