
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardStat from "components/Statistics/CardStat";
import { useTranslation } from "react-i18next";
import usePricing from "utils/Pricing";


const Header = (props) => {
  const { t } = useTranslation();
  const pricing = usePricing();

  return (
    <>
      <div className="header">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <CardStat
                  title={t("common.Users")}
                  value={props.users}
                  icon="users"
                  text={t("dashboard.TotalUsersText")}
                />
              </Col>
              <Col lg="6" xl="3">
                <CardStat
                  title={t("common.Orders")}
                  value={props.orders}
                  icon="list"
                  text={t("dashboard.TotalOrdersText")}
                />
              </Col>
              <Col lg="6" xl="3">
                <CardStat
                  title={t("dashboard.TotalSales")}
                  value={pricing.format(props.salesTotal)}
                  icon="coins"
                  text={t("dashboard.TotalSalesText")}
                />
              </Col>
              <Col lg="6" xl="3">
                <CardStat
                  title={t("dashboard.SalesThisMonth")}
                  value={pricing.format(props.salesThisMonth)}
                  icon="coins"
                  text={t("dashboard.SalesThisMonthText")}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
