import OrderHistoryTable from "components/Orders/OrderHistoryTable";
import React, { useEffect, useState } from "react";

import {
    Container,
    Row,
    Col,
    Alert,
    UncontrolledAlert
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { CUSTOMER_STATISTICS_PATH } from "variables/constants";
import CardStat from "components/Statistics/CardStat";
import { useTranslation } from "react-i18next";
import useStore from "store/store";
import useSettings from "../utils/Settings";



const CustomerDashboard = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const settings = useSettings()
    const [statistics, setStatistics] = useState(null);
    const { isBusinessUser } = useStore();

    useEffect(() => {
        Blitzcrank.get(CUSTOMER_STATISTICS_PATH, (status, data) => {
            if (status === 200) {
                setStatistics(data.userStatistics);
            }
        })
    }, []);

    return (
        <>
            <Container fluid>
                {(!isBusinessUser || settings.warningNotification.show) && <Row>
                    {settings.warningNotification.show && <Col lg="4" md="6" sm="8" xs="12">
                        <Alert color="danger">
                            {t("notifications.warningNotification")}
                        </Alert>
                    </Col>}
                    {!isBusinessUser && <Col lg="4" md="6" sm="8" xs="12">
                        <UncontrolledAlert color="info">
                            {t("notifications.BusinessUserEmptyDataAlert")}
                        </UncontrolledAlert>
                    </Col>}
                </Row>}
                <h2 className="text-muted mb-4">{t("dashboard.OrdersPreview")}</h2>
                {statistics && <Row>
                    <Col lg="6" xl="4">
                        <CardStat
                            title={t("dashboard.PendingOrders")}
                            value={statistics.userPendingOrders}
                            icon="list"
                            text={t("dashboard.PendingOrdersText")}
                            color="yellow"
                        />
                    </Col>
                    <Col lg="6" xl="4">
                        <CardStat
                            title={t("dashboard.FinishedOrders")}
                            value={statistics.userFinishedOrders}
                            icon="list"
                            text={t("dashboard.FinishedOrdersText")}
                            color="green"
                        />
                    </Col>
                </Row>}

                <h2 className="text-muted mt-4 mb-4">{t("dashboard.OrderHistory")}</h2>
                <Row>
                    <Col lg="12">
                        <OrderHistoryTable />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CustomerDashboard;
