import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "utils/Cookies";
import { COOKIE } from "variables/constants";
import { COOKIE_CONSENT_TYPE } from "variables/constants";
import { INPUT_CURRENCY } from "variables/constants";
import { USER_ROLES } from "variables/constants";

const AppContext = createContext();

export function StoreWrapper({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currency, setCurrency] = useState(INPUT_CURRENCY);
  const [visitorCookiesEnabled, setVisitorsCookiesEnabled] = useState(false);

  const isUserLogged = currentUser !== null;
  const isUserAdmin = isUserLogged && currentUser.role > USER_ROLES.CUSTOMER;
  const isUserMaster = isUserLogged && currentUser.role === USER_ROLES.MASTER;
  const isBusinessUser = isUserLogged && currentUser.isBusinessUser > 0;

  useEffect(() => {
    const visitorsCookie = getCookieConsent();
    if(visitorsCookie > COOKIE_CONSENT_TYPE.STRICT) setVisitorsCookiesEnabled(true);

    const cookieCurrency = Cookies.getCookie(COOKIE.CURR);
    if(cookieCurrency) setCurrency(cookieCurrency);
  }, []);

  const changeCurrency = (curr) => {
    if(visitorCookiesEnabled) Cookies.setCookie(COOKIE.CURR, curr, 99999);
    setCurrency(curr);
  }

  const deleteCurrency = () => {
    Cookies.deleteCookie(COOKIE.CURR);
    setCurrency(INPUT_CURRENCY);
  }

  const logoutUser = () => {
    setCurrentUser(null)
  }

  const loginUser = (session) => {
    setCurrentUser({
      fullName: session.firstName + " " + session.lastName, 
      id: session.id,
      email: session.email,
      role: session.role,
      isBusinessUser: session.isBusinessUser
    })
  }

  const updateCookieConsent = (consent) => {
    Cookies.setCookie(COOKIE.CC, consent, 365);

    if(consent > COOKIE_CONSENT_TYPE.STRICT) {
      setVisitorsCookiesEnabled(true);
    } else {
      setVisitorsCookiesEnabled(false);
      Cookies.deleteCookie(COOKIE.LANG);
      Cookies.deleteCookie(COOKIE.CURR)
    }
  }

  const updateLanguageCookie = (lang) => {
    if(visitorCookiesEnabled) Cookies.setCookie(COOKIE.LANG, lang, 365);
  }

  const getCookieConsent = () => {
    const cookie = Cookies.getCookie(COOKIE.CC);
    return cookie ? parseInt(cookie) : cookie;
  }


  let sharedState = {
    isUserLogged,
    isUserAdmin,
    isUserMaster,
    isBusinessUser,
    currentUser,
    loginUser,
    logoutUser,
    currency,
    changeCurrency,
    deleteCurrency,
    updateCookieConsent,
    getCookieConsent,
    updateLanguageCookie,
    visitorCookiesEnabled
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

//import this function wherever you want to use the store above
export default function useStore() {
  return useContext(AppContext);
}
