
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Col,
  Media,
  Container,
  Row,
  Alert
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";


const PageBuildReader = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t, i18n } = useTranslation();
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    Blitzcrank.get("./public/templates/" + props.template + "_" + i18n.language + ".json", (status, data) => {
      if (status === 200) {
        setPageContent(data);
      }
    })
  }, [i18n.language, props.template])

  const contentWrap = (jsx) => {
    return <Row className="mb-3"><Col>{jsx}</Col></Row>
  }

  return (
    <>
      <Container fluid>
        {/* <h3>{t('common.welcome')}</h3> */}
        {pageContent && pageContent.blocks.map((element) => {
          const content = element.data;
          let res = null;

          switch (element.type) {
            case "header":
              const HeaderTag = `h${content.level}`;
              res = <HeaderTag>{content.text}</HeaderTag>
              break;
            case "image":
              res = <img alt={content.caption} style={{ width: "100%" }} src={content.file.url} />
              break;
            case "paragraph":
              res = <p><span dangerouslySetInnerHTML={{__html: content.text}}></span></p>
              break;
            case "delimiter":
              res = <div class="ce-delimiter cdx-block"></div>
              break;
            case "warning":
              res = <Alert color="info">
                <h4 className="alert-heading">{content.title}</h4>
                <p>
                  {content.message}
                </p>
              </Alert>
              break;
            case "raw":
              res = <div dangerouslySetInnerHTML={{__html: content.html}}/>
              break;  
            default:
              break;
          }

          return contentWrap(res);

        })}
      </Container>
    </>
  );
};

export default PageBuildReader;
