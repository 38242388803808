
/*eslint-disable*/
import CookieSettingsController from "components/Cookies/CookieSettingsController";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col, ListGroup, ListGroupItem, Button } from "reactstrap";
import routes from "routes";
import useSettings from "utils/Settings";
import FooterContactUs from "./FooterContactUs";
import FooterSocialLinks from "./FooterSocialLinks";
import QuickLinks from "./QuickLinks";

const AuthFooter = () => {
  const { showCurrencySwitch, showLanguageSwitch } = useSettings();
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="pt-2">
            <Col xl="4" className="text-center text-xl-left">
              <FooterContactUs isDarkBackground />
            </Col>
            <Col xl="4" className="text-center">
              {showLanguageSwitch && <LanguageSwitcher />}
              {showCurrencySwitch && <CurrencySwitcher />}
              <br />
              <CookieSettingsController />
            </Col>
            <Col xl="4" className="text-center text-xl-right">
              <FooterSocialLinks isDarkBackground />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="4">

              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="/"
                  target="_blank"
                >
                  DiGiDENT
                </a>
              </div>
            </Col>
            <Col xl="4">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    href="https://www.vecteezy.com/free-vector/abstract"
                    target="_blank"
                  >
                    Abstract Vectors by Vecteezy
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default AuthFooter;
