import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "store/store";
import Cookies from "utils/Cookies";
import { CURRENCIES } from "variables/constants";
import { CURRENCY_TEXT } from "variables/constants";
import { USER_ROLES } from "variables/constants";
import useSettings from "./Settings";

const AppContext = createContext();

export function PricingWrapper({ children }) {
    const {t} = useTranslation();
    const {currencyTransferRates} = useSettings();
    const store = useStore();

    const floatPrice = (price) => {
        if(typeof price === "string") price = parseFloat(price);
        return price;
    }

    const addAll = (prices) => {
        let total = 0;
        prices.forEach(price => {
            total+=parseFloat(price)
        });
        return total;
    }

    const calculteByCurrency = (price) => {
        let rate = 1;
        price = floatPrice(price);
        switch(store.currency) {
            case CURRENCIES.BGN:
                return price;
            case CURRENCIES.EUR:
                rate = 1.95;
                if(currencyTransferRates.hasOwnProperty(CURRENCIES.EUR)) {
                    rate = currencyTransferRates[CURRENCIES.EUR];
                }
                return parseFloat((price/rate).toFixed(2));
            default: 
                return price;        
        }
    }


    const calculate = (price, quantity, discount) => {
        price = floatPrice(price);
        return calculateDouble(price, quantity, discount) + " " + t(CURRENCY_TEXT[store.currency]);
    }

    const calculateDouble = (price, quantity, discount) => {
        price = floatPrice(price);
        price = calculteByCurrency(price);
        if(!quantity) quantity = 1;
        let total = (price * quantity);
        if (total <= 0) total = price;
        if (discount) {
          total = total - ((discount / 100) * total);
        }
        return total ? total.toFixed(2) : total;
    }

    const format = (price) => {
        price = floatPrice(price);
        return formatDouble(price) + " " + t(CURRENCY_TEXT[store.currency])
    }

    const formatDouble = (price) => {
        price = floatPrice(price);
        price = calculteByCurrency(price);
        return price.toFixed(2);
    }


    let sharedState = {
        format,
        formatDouble,
        calculate,
        calculateDouble,
        addAll
    };

    return (
        <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
    );
}

//import this function wherever you want to use the store above
export default function usePricing() {
    return useContext(AppContext);
}
