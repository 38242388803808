import React, { useState } from "react";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { FORGOTTEN_PASSWORD_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import useSettings from "utils/Settings";
import { FormWrapper } from "components/FormGroup/FormWrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordResetSchema } from "schemas/ValidationSchemas";
import { useForm } from "react-hook-form";
import FormSubmit from "components/FormGroup/FormSubmit";
import FormInputController from "components/FormGroup/FormInputController";


const PasswordReset = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const { validations } = useSettings();
    const { addToast } = useToasts();

    const formPasswordReset = useForm({
        resolver: yupResolver(PasswordResetSchema(t, validations, false))
    });

    const resetPassword = (data) => {
        Blitzcrank.post(FORGOTTEN_PASSWORD_PATH, {
            email: data.email
        }, (status, data) => {
            if (status === 200) {
                addToast(t("toasts.ForgottenPasswordSuccess"), { appearance: TOAST_TYPE.SUCCESS });
            } else {
                addToast(t("toasts.ForgottenPasswordFailure"), { appearance: TOAST_TYPE.ERROR });

            }
        })
    }

    return (
        <>
            <Col lg="5" md="7">
                <Card>
                    <CardBody>
                        <div className="text-center text-muted mb-4">
                            <small>{t("auth.EnterYourEmail")}</small>
                        </div>

                        <FormWrapper form={formPasswordReset}>
                            <FormInputController
                                type="email"
                                label={t("common.Email")}
                                id={"email"}
                            />

                            <Row className="mt-4">
                                <Col className="text-center">
                                    <FormSubmit color="primary" onClick={resetPassword}>{t("auth.ResetPassword")}</FormSubmit>
                                </Col>
                            </Row>
                        </FormWrapper>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default PasswordReset;
