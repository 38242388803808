import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Container
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUs } from "components/ContactUs/ContactUs";
import PageBuildReader from "components/PageBuilder/PageBuildReader";

export const OurServices = (props) => {

    return <>
        <PageBuildReader template={"our-services"}/>
    </>
}