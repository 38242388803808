
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table } from "reactstrap";
import useStore from "store/store";
import Cookies from "utils/Cookies";
import { COOKIE_POLICY_URL } from "variables/constants";
import { COOKIE_CONSENT_TYPE } from "variables/constants";
import CookiePreferancesPanel from "./CookiePreferancesPanel";

// reactstrap components

const CookieConsentModal = (props) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const store = useStore();

    useEffect(() => {
        const consentCookie = store.getCookieConsent();
        if(!consentCookie) {
            setIsModalOpen(true)
        }
    }, [store.getCookieConsent]);


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <Modal className="cookie-consent-modal" backdrop={"static"} isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader>
                {t("common.Cookies.PolicyTitle")}
            </ModalHeader>
            <ModalBody>
                <CookiePreferancesPanel visitorsEnabled={true} onConfirm={toggleModal}/>
            </ModalBody>
        </Modal>
    );
};

export default CookieConsentModal;
