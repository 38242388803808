import axios from "axios";
import { createContext, useContext, useState } from "react";
import { APP_URL } from "variables/constants";

const AppContext = createContext();

export function BlitzcrankWrapper({ children }) {

    const [loaders, setLoaders] = useState([]);

    const isLoading = loaders.length > 0;

    const service = axios.create({
        baseURL: APP_URL
    });
    service.defaults.withCredentials = true
    
    const handleSuccess = (response) => {
        return response;
    }

    const handleError = (error) => {
        let status = null;
        if (error && error.response && error.response.status) {
            status = error.response.status;
        }
        const loginPath = "/auth/login";
        const locationPath = window.location.pathname;
        const errorPath = "/error";
        switch (status) {
            case 401:
                if (locationPath !== loginPath) {
                    //window.location.href = loginPath
                }
                break;
            case 403:
                if (locationPath !== loginPath) {
                    window.location.href = loginPath
                }
                break;
            default:
                if (locationPath !== errorPath) {
                    //window.location.href = "/error"
                }
                break;
        }
        return Promise.reject(error)
    }

    service.interceptors.response.use(handleSuccess, handleError);

    const safeErrorCallback = (error, callback) => {
        let status = 0;

        if (error && error.response) {
            status = error.response.status;
        }

        callback(status, null)
    }

    const addLoader = () => {
        const newLoaders = [...loaders];
        newLoaders.push(1);
        setLoaders(newLoaders);
    }

    const removeLoader = () => {
        const newLoaders = [...loaders];
        newLoaders.pop();
        setLoaders(newLoaders);
    }

    const request = (method, path, payload, callback, config = {}) => {
        const showPreLoader = ["PATCH", "POST", "PUT", "DELETE"].indexOf(method) > -1;
        if(showPreLoader) addLoader();
        return service.request({
            method: method,
            url: path,
            responseType: config.responseType || 'json',
            data: payload
        }).then(
            (response) => {
                if(showPreLoader) removeLoader();
                callback(response.status, response.data)
            }
        ).catch(
            (error) => {
                if(showPreLoader) removeLoader();
                safeErrorCallback(error, callback)
            }
        )
    }

    const get = (path, callback, config = {}) => {
        return request("GET", path, null, callback, config);
    }

    const patch = (path, payload, callback, config = {}) => {
        return request("PATCH", path, payload, callback, config);
    }

    const post = (path, payload, callback, config = {}) => {
        return request("POST", path, payload, callback, config);
    }

    const deleteAction = (path, callback, config = {}) => {
        return request("DELETE", path, null, callback, config);
    }

    const update = (path, payload, callback, config = {}) => {
        return request("PUT", path, payload, callback, config);
    }

    let sharedState = {
        get,
        post,
        update,
        "delete": deleteAction,
        patch,
        isLoading
    };

    return (
        <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
    );
}

//import this function wherever you want to use the store above
export default function useBlitzcrank() {
    return useContext(AppContext);
}
