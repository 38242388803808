
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerCarousel from "components/Carousel/BannerCarousel";
import MultiCarousel from "components/Carousel/MultiCarousel";
import { ContactUs } from "components/ContactUs/ContactUs";
import GoogleMap from "components/GoogleMap/GoogleMap";
import PageBuildReader from "components/PageBuilder/PageBuildReader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Col,
  Media,
  Container,
  Row,
  Alert,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  UncontrolledCollapse,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { WHY_CHOOSE_US_LIST_PATH } from "variables/constants";
import { OUR_SERVICES_LIST_PATH } from "variables/constants";
import { HOMEPAGE_CAROUSEL_LIST_PATH } from "variables/constants";
import { APP_URL } from "variables/constants";


const Homepage = () => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const [activeServiceIndex, setActiveServiceIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);
  const [ourServicesList, setOurServicesList] = useState([]);
  const [whyChooseUsList, setWhyChooseUsList] = useState([]);

  useEffect(() => {
    Blitzcrank.get(APP_URL + HOMEPAGE_CAROUSEL_LIST_PATH, (status, data) => {
      if (status === 200) setCarouselItems(data);
    });
    Blitzcrank.get(APP_URL + OUR_SERVICES_LIST_PATH, (status, data) => {
      if (status === 200) setOurServicesList(data);
    });
    Blitzcrank.get(APP_URL + WHY_CHOOSE_US_LIST_PATH, (status, data) => {
      if (status === 200) setWhyChooseUsList(data);
    });
  }, [])

  return (
    <>

      <div className="accent-pattern-bg">
        <Container>
          <Row>
            <BannerCarousel items={carouselItems} />
          </Row>
        </Container>
      </div>



      <div className="homepage">
        <Container>
          <Row>
            <Col>
              <Row className="text-center">
                <Col>
                  <h1>{t("homepage.AboutUs")}</h1>
                </Col>
              </Row>
              <p>
                {t("homepage.AboutUsText")}
              </p>
            </Col>
          </Row>
        </Container>

        <div className="mt-6 homepage-section-background accent-pattern-bg">
          <Container>
            <Row className="text-center">
              <Col>
                <h2>{t("homepage.OurServices")}</h2>
              </Col>
            </Row>

            <MultiCarousel activeIndex={activeServiceIndex} onNavItemChange={(index) => setActiveServiceIndex(index)} items={ourServicesList} />

            <Row className="mt-3">
              <Col>
                {ourServicesList[activeServiceIndex] && <Card className="light-background-card">
                  <CardBody className="text-center">
                    <h3>{t(ourServicesList[activeServiceIndex].title)}</h3>
                    <p>{t(ourServicesList[activeServiceIndex].description)}</p>
                  </CardBody>
                </Card>}

              </Col>
            </Row>
          </Container>
        </div>

        <Container>

          <Row className="mt-6">
            <Col>
              <Row className="text-center">
                <Col>
                  <h2>{t("homepage.WhyChooseUs")}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup className="collapse-group">
                    {whyChooseUsList.map((item, index) => {
                      const itemId = "wcu_" + index;
                      return <ListGroupItem>
                        <ListGroupItemHeading id={itemId}>{t(item.title)}</ListGroupItemHeading>
                        <UncontrolledCollapse defaultOpen={index === 0} toggler={"#" + itemId}>
                          <ListGroupItemText>
                            {t(item.description)}
                          </ListGroupItemText>
                        </UncontrolledCollapse>
                      </ListGroupItem>
                    })}
                  </ListGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-6">
            <Col>
              <Row className="text-center">
                <Col>
                  <h2>{t("homepage.OurPromise")}</h2>
                </Col>
              </Row>
              <p>
                {t("homepage.OurPromiseText")}
              </p>
            </Col>
          </Row>
        </Container>

        <div className="mt-6 homepage-section-background accent-pattern-bg">
          <Container>
            <Row className="text-center">
              <Col>
                <h2>{t("common.ContactUs")}</h2>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="6" md="10" sm="12" className="pt-2">
                <Card className="light-background-card" style={{height:"100%"}}>
                  <CardBody>
                    <ContactUs centered />
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="10" sm="12" className="pt-2">
                <Card className="light-background-card" style={{height:"100%"}}>
                  <CardBody>
                    <GoogleMap/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

      </div>


    </>
  );
};

export default Homepage;
