import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "./Button";

export const BackToTopButton = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <>
        {scrollPosition > 500 && <Button className="back-to-top-button show-desktop" onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }} icon="angle-double-up" color="primary" />}
    </>
}