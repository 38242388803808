import React from "react";
import useFormWrap from "./FormWrap";
import { Button } from "components/Buttons/Button";

const FormSubmit = (props) => {
    const { children, onClick } = props;
    const { form } = useFormWrap();
    const { handleSubmit } = form;
    return <Button {...props} onClick={handleSubmit(onClick)}>
        {children}
    </Button>
}

export default FormSubmit;