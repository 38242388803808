import React, { useEffect, useState } from "react";

import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Label,
    Input,
    Form,
    Row,
    Col
} from "reactstrap";
import useStore from "store/store";

import useBlitzcrank from "requests/BlitzcrankWrapper";
import { USERS_ADMIN_PATH, USERS_PATH } from "variables/constants";
import Transformer from "transformers/Transformer";
import { USER_ROLES_TEXT } from "variables/constants";
import { REGISTER_PATH } from "variables/constants";
import { Button } from "components/Buttons/Button";
import { USER_ROLES } from "variables/constants";
import { Paginator } from "components/Pagination/Paginator";
import { TableSearch } from "components/Tables/TableSearch";
import { TableHeaderSortable } from "components/Tables/TableHeaderSortable";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import Utils from "utils/Utils";
import { useLocation } from "react-router-dom";


const UsersMaintenance = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const store = useStore();
    const location = useLocation();
    const defaultNewUserData = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: ""
    }
    const [users, setUsers] = useState([]);
    const [userModal, setUserModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [newUser, setNewUser] = useState(defaultNewUserData);
    const [newUserModal, setNewUserModal] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [businessDetails, setBusinessDetails] = useState(null);
    const [businessDetailsModal, setBusinessDetailsModal] = useState(false);
    const [totalOrderRows, setTotalOrderRows] = useState(0);
    const { addToast } = useToasts();

    const toggleUserModal = () => {
        const newModalState = !userModal;
        if (!newModalState) setEditingUser(null);
        setUserModal(newModalState);
    }

    const toggleNewUserModal = () => {
        const newModalState = !newUserModal;
        if (!newModalState) setNewUser(defaultNewUserData);
        setNewUserModal(newModalState);
    }

    const toggleBusinessDetailsModal = () => {
        const newModalState = !businessDetailsModal;
        if (!newModalState) setBusinessDetailsModal(null);
        setBusinessDetailsModal(newModalState);
    }

    const toggleDeleteUserModal = () => {
        const newModalState = !deleteUserModal;
        if (!newModalState) setDeleteUser(null);
        setDeleteUserModal(newModalState);
    }

    useEffect(() => {
        if (store.isUserLogged) {
            Blitzcrank.get(USERS_PATH + Utils.getBasicTableParams(location), (status, data) => {
                if (status === 200) {
                    setUsers(Transformer.parse(data.rows));
                    setTotalOrderRows(data.totalRows);
                }
            })
        }
    }, [store.isUserLogged, location]);

    const onEditingUserInputChange = (prop, value) => {
        const newUser = { ...editingUser };
        newUser[prop] = value;
        setEditingUser(newUser);
    }

    const onNewUserInputChange = (prop, value) => {
        const newUserState = { ...newUser };
        newUserState[prop] = value;
        setNewUser(newUserState);
    }

    const saveUserEdit = () => {
        Blitzcrank.update(USERS_ADMIN_PATH, {
            id: editingUser.id,
            firstName: editingUser.firstName,
            lastName: editingUser.lastName,
            phoneNumber: editingUser.phoneNumber,
            role: editingUser.role
        }, (status, data) => {
            if (status === 200) {
                addToast(t("toasts.UserUpdateSuccess", { appearance: TOAST_TYPE.SUCCESS }));
                toggleUserModal();
                Blitzcrank.get(USERS_PATH, (getStatus, getData) => {
                    if (getStatus === 200) {
                        setUsers(Transformer.parse(getData.rows));
                    }
                })
            } else {
                addToast(t("toasts.UserUpdateFailure", { appearance: TOAST_TYPE.ERROR }));
            }
        })
    }

    const saveNewUser = () => {
        Blitzcrank.post(REGISTER_PATH, {
            email: newUser.email,
            password: newUser.password,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            phoneNumber: newUser.phoneNumber
        }, (status, data) => {
            if (status === 200) {
                toggleNewUserModal();
                addToast(t("toasts.UserRegisterSuccess", { appearance: TOAST_TYPE.SUCCESS }));
                Blitzcrank.get(USERS_PATH, (getStatus, getData) => {
                    if (getStatus === 200) {
                        setUsers(Transformer.parse(getData.rows));
                    } else {
                        addToast(t("toasts.UserRegisterFailure", { appearance: TOAST_TYPE.ERROR }));
                    }
                })
            } else {
                addToast(t("toasts.UserRegisterFailure", { appearance: TOAST_TYPE.ERROR }));
            }
        })
    }
    const deleteUserHandler = () => {
        Blitzcrank.delete(USERS_PATH + "/" + deleteUser.id, (status, data) => {
            if (status === 200) {
                toggleDeleteUserModal();
                addToast(t("toasts.UserDeleteSuccess", { appearance: TOAST_TYPE.SUCCESS }));
                Blitzcrank.get(USERS_PATH, (getStatus, getData) => {
                    if (getStatus === 200) {
                        setUsers(Transformer.parse(getData.rows));
                    } else {
                        addToast(t("toasts.UserDeleteFailure", { appearance: TOAST_TYPE.ERROR }));

                    }
                })
            } else {
                addToast(t("toasts.UserDeleteFailure", { appearance: TOAST_TYPE.ERROR }));
            }
        })
    }

    const onDeleteUserClick = (user) => {
        toggleDeleteUserModal();
        setDeleteUser({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName
        });
    }

    const onEditUserClick = (user) => {
        toggleUserModal();
        setEditingUser(user);
    }

    const onBusinessDetailsClick = (user) => {
        toggleBusinessDetailsModal();
        setBusinessDetails(user);
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="3" md="4">
                        <TableSearch
                            placeholder={t("common.Search") + " (" + t("common.Email") + ")"}
                            endpoint={USERS_PATH}
                        />
                    </Col>
                </Row>

                <Table className="align-items-center" responsive>
                    <TableHeaderSortable
                        headers={[
                            {
                                text: t("common.Name")
                            },
                            {
                                text: t("common.Email"),
                                sortable: true,
                                id: "email"
                            },
                            {
                                text: t("common.Role")
                            },
                            {
                                text: ""
                            }
                        ]}
                    />
                    <tbody>
                        {users.map((user) => {
                            return <tr key={"ukey_" + user.id}>
                                <th scope="row">
                                    <span className="mb-0 text-sm">
                                        {user.firstName} {user.lastName}
                                    </span>
                                </th>
                                <td>{user.email}</td>
                                <td>{USER_ROLES_TEXT[user.role]} ({user.role})</td>
                                <td className="text-right">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-icon-only text-light"
                                            href="#pbl"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={e => e.preventDefault()}
                                        >
                                            <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem
                                                href="#pbl"
                                                disabled={!user.isBusinessUser}
                                                onClick={() => onBusinessDetailsClick(user)}
                                            >
                                                {t("common.BusinessDetails")}
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#pbl"
                                                onClick={() => onEditUserClick(user)}
                                            >
                                                {t("common.Edit")}
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#pbl"
                                                onClick={() => onDeleteUserClick(user)}
                                            >
                                                {t("common.Delete")}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>

                <Row>
                    <Col>
                        <Paginator
                            totalRows={totalOrderRows} rightAligned />
                    </Col>
                </Row>

                <Button icon="plus" onClick={toggleNewUserModal}>{t("common.CreateNewUser")}</Button>

                <Modal isOpen={userModal} toggle={toggleUserModal}>
                    <ModalHeader toggle={toggleUserModal}>
                        {t("common.EditUser")}
                    </ModalHeader>
                    <ModalBody>
                        {editingUser && <Form>
                            <FormGroup>
                                <Label for="email">{t("common.Email")}</Label>
                                <Input value={editingUser.email} readOnly={true} type="email" name="email" id="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="firstName">{t("common.FirstName")}</Label>
                                <Input value={editingUser.firstName}
                                    onChange={(e) => onEditingUserInputChange("firstName", e.target.value)}
                                    type="text" name="firstName" id="firstName" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastName">{t("common.LastName")}</Label>
                                <Input value={editingUser.lastName}
                                    onChange={(e) => onEditingUserInputChange("lastName", e.target.value)}
                                    type="text" name="lastName" id="lastName" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phoneNumber">{t("common.PhoneNumber")}</Label>
                                <Input value={editingUser.phoneNumber}
                                    onChange={(e) => onEditingUserInputChange("phoneNumber", e.target.value)}
                                    type="text" name="phoneNumber" id="phoneNumber" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="role">{t("common.Role")}</Label>
                                <Input disabled={store.currentUser.role !== USER_ROLES.MASTER} value={editingUser.role}
                                    onChange={(e) => onEditingUserInputChange("role", e.target.value)} type="select"
                                    name="role" id="role">
                                    {Object.keys(USER_ROLES_TEXT).map((roleId) => {
                                        return <option value={roleId}>{USER_ROLES_TEXT[roleId]}</option>
                                    })}
                                </Input>
                            </FormGroup>
                        </Form>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={saveUserEdit}>{t("common.Save")}</Button>{' '}
                        <Button color="secondary" onClick={toggleUserModal}>{t("common.Cancel")}</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={newUserModal} toggle={toggleNewUserModal}>
                    <ModalHeader toggle={toggleNewUserModal}>
                        {t("common.CreateNewUser")}
                    </ModalHeader>
                    <ModalBody>
                        {newUser && <Form>
                            <FormGroup>
                                <Label for="email">{t("common.Email")}</Label>
                                <Input value={newUser.email}
                                    onChange={(e) => onNewUserInputChange("email", e.target.value)} type="email"
                                    name="email" id="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">{t("common.Password")}</Label>
                                <Input value={newUser.password}
                                    onChange={(e) => onNewUserInputChange("password", e.target.value)}
                                    type="password" name="password" id="password" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="firstName">{t("common.FirstName")}</Label>
                                <Input value={newUser.firstName}
                                    onChange={(e) => onNewUserInputChange("firstName", e.target.value)} type="text"
                                    name="firstName" id="firstName" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastName">{t("common.LastName")}</Label>
                                <Input value={newUser.lastName}
                                    onChange={(e) => onNewUserInputChange("lastName", e.target.value)} type="text"
                                    name="lastName" id="lastName" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phoneNumber">{t("common.PhoneNumber")}</Label>
                                <Input value={newUser.phoneNumber}
                                    onChange={(e) => onNewUserInputChange("phoneNumber", e.target.value)} type="text"
                                    name="phoneNumber" id="phoneNumber" />
                            </FormGroup>
                        </Form>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={saveNewUser}>{t("common.Save")}</Button>{' '}
                        <Button color="secondary" onClick={toggleNewUserModal}>{t("common.Cancel")}</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={deleteUserModal} toggle={toggleDeleteUserModal}>
                    <ModalBody>
                        {deleteUser &&
                            <div> {`${t("common.DeleteUserQuestion")} ${deleteUser.firstName} ${deleteUser.lastName} ?`}</div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={deleteUserHandler}>{t("common.Delete")}</Button>{' '}
                        <Button color="secondary" onClick={toggleDeleteUserModal}>{t("common.Cancel")}</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={businessDetailsModal} toggle={toggleBusinessDetailsModal}>
                    <ModalHeader>{t("common.BusinessDetails")}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                {businessDetails && <ul class="unstyled-list">
                                    <li>
                                        <span className="text-muted">{t("common.BusinessName")}</span>:&nbsp;
                                        {businessDetails.businessName}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.Bulstat")}</span>:&nbsp;
                                        {businessDetails.bulstat}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.Mol")}</span>:&nbsp;
                                        {businessDetails.mol}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.AddressLine")}</span>:&nbsp;
                                        {businessDetails.businessAddress}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.City")}</span>:&nbsp;
                                        {businessDetails.businessCity}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.Country")}</span>:&nbsp;
                                        {businessDetails.businessCountry}
                                    </li>
                                    <li>
                                        <span className="text-muted">{t("common.PhoneNumber")}</span>:&nbsp;
                                        {businessDetails.businessPhone}
                                    </li>
                                </ul>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleBusinessDetailsModal}>{t("common.Cancel")}</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default UsersMaintenance;
