import {
    Button as ReactButton
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Button = (props) => {
    
    return <ReactButton {...props}>
        {props.icon && <FontAwesomeIcon className={props.children ? "mr-2" : ""} icon={props.icon} />}
        {props.children}
    </ReactButton>
}