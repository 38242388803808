import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";

import { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

export function FormWrapper({ children, form }) {
    const sharedState = {
        form
    }
    return (
        <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
    );
}

//import this function wherever you want to use the store above
export default function useFormWrap() {
    return useContext(AppContext);
}
