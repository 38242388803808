
/*eslint-disable*/
import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import useStore from "store/store";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { LOGOUT_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const Blitzcrank = useBlitzcrank();

  const [collapseOpen, setCollapseOpen] = useState();
  const store = useStore();
  const { addToast } = useToasts();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes, divided) => {
    if (divided) {
      routes = routes.filter((route) => route.dividedInSidebar)
    } else {
      routes = routes.filter((route) => !route.dividedInSidebar)
    }
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <i className={prop.icon} />
            {t(prop.translation)}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const logout = () => {
    Blitzcrank.post(LOGOUT_PATH, {}, (status, data) => {
      addToast(t("toasts.LogoutSuccess"), { appearance: TOAST_TYPE.SUCCESS });
      store.logoutUser();
    })
  }

  const formatToInitials = (fullName) => {
    let names = fullName.split(" ");
    names = names.filter((name) => !!name).slice(0, 2).map((name) => {
      try {
        return name.trim();
      } catch (ex) {
        return name;
      }
    });
    return names.map((name) => {
      try {
        return name[0].toUpperCase();
      } catch (ex) {
        return name;
      }
    });
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          {store.currentUser && <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <strong className="mobile-sidebar-avatar">{formatToInitials(store.currentUser.fullName)}</strong>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{t("common.Welcome")}!</h6>
              </DropdownItem>
              <DropdownItem to={store.isUserAdmin ? "/admin/user-profile" : "/public/user-profile"} tag={Link}>
                <i className="ni ni-single-02" />
                <span>{t("nav.MyProfile")}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pbl" onClick={logout}>
                <i className="ni ni-user-run" />
                <span>{t("nav.Logout")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>}
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>
            {createLinks(routes)}
          </Nav>
          <hr className="my-3" />
          <Nav navbar>
            {createLinks(routes, true)}
          </Nav>
          <hr className="my-3" />
          <Nav className="logout-sidebar show-desktop" navbar>
            <NavItem>
              <NavLink
                to={"#logout"}
                tag={NavLinkRRD}
                onClick={logout}
              >
                <i className="ni ni-user-run" />
                {t("nav.Logout")}
              </NavLink>
            </NavItem>
          </Nav>
          {/* Divider */}
          {/*           <hr className="my-3" /> */}
          {/* Heading */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
