import React from "react";

import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardStat = (props) => {
    let color = props.color || "teal";
    return (
        <>
            <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                    {props.title}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">{props.value}</span>
                            </div>
                            <Col className="col-auto">
                                <div className={"icon icon-shape text-white rounded-circle shadow bg-" + color}>
                                    <FontAwesomeIcon icon={props.icon} />
                                </div>
                            </Col>

                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                            <span className="text-nowrap">{props.text}</span>
                        </p>
                    </CardBody>
                </Card>
        </>
    );
};

export default CardStat;
