import axios from "axios";
import CookiePolicyPlain from "components/Cookies/CookiePolicyPlain";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { COOKIE_POLICY_URL } from "variables/constants";


const CookiePolicy = (props) => {
    return <>
        <Container fluid>
            <CookiePolicyPlain />
        </Container>
    </>
};

export default CookiePolicy;
