import { createContext, useContext, useEffect, useState } from "react";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import Transformer from "transformers/Transformer";
import { FOOTER_BANNERS_PATH } from "variables/constants";
import { VALIDATIONS_PATH } from "variables/constants";
import { INPUT_CURRENCY } from "variables/constants";
import { COOKIE } from "variables/constants";
import { SETTINGS_PATH } from "variables/constants";
import Cookies from "./Cookies";

const AppContext = createContext();

export function SettingsWrapper({ children }) {
    const Blitzcrank = useBlitzcrank();

    const [settings, setSettings] = useState({});
    const [validations, setValidations] = useState({});
    const [footerBanners, setFooterBanners] = useState([])
    const store = useStore();

    const showCurrencySwitch = (settings && settings.showCurrencySwitch) || false;
    const showLanguageSwitch = (settings && settings.showLanguageSwitch) || false;
    const warningNotification = (settings && settings.warningNotification) || false;
    const socialLinks = (settings && settings.socialLinks) || null;
    const currencyTransferRates = (settings && settings.currencyTransferRates) || {};
    let deliveryCost = settings && settings.deliveryCost && typeof settings.deliveryCost === "number" ? settings.deliveryCost : 0;
    const cashPaymentCost = settings && settings.cashPaymentCost && typeof settings.cashPaymentCost === "number" ? settings.cashPaymentCost : 0;
    const freeDeliveryGoal = settings && settings.freeDeliveryGoal && typeof settings.freeDeliveryGoal === "number" ? settings.freeDeliveryGoal : -1;

    useEffect(() => {
        Blitzcrank.get(SETTINGS_PATH, (status, data) => {
            if (status === 200) {
                setSettings(data);

                if (!data.showCurrencySwitch) {
                    store.deleteCurrency();
                }

                if (!data.showLanguageSwitch) {
                    Cookies.deleteCookie(COOKIE.LANG);
                }
            }
        });
        Blitzcrank.get(VALIDATIONS_PATH, (status, data) => {
            if (status === 200) {
                const vals = Transformer.parseValidations(data);
                setValidations(vals);
            }
        });
        Blitzcrank.get(FOOTER_BANNERS_PATH, (status, data) => {
            if (status === 200) {
                setFooterBanners(data);
            }
        });
    }, [])

    let sharedState = {
        showCurrencySwitch,
        showLanguageSwitch,
        warningNotification,
        socialLinks,
        currencyTransferRates,
        footerBanners,
        deliveryCost,
        cashPaymentCost,
        freeDeliveryGoal,
        validations
    };

    return (
        <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
    );
}

//import this function wherever you want to use the store above
export default function useSettings() {
    return useContext(AppContext);
}
