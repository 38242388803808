class Transformer {

  parse(resp) {
    if (resp) {
      if (Array.isArray(resp)) {
        return resp.map((item) => {
          const res = {};
          Object.keys(item).forEach((key) => {
            let keyIndex = 0;
            while (key[keyIndex] && key[keyIndex] === key[keyIndex].toUpperCase()) {
              keyIndex++
            }
            res[key.slice(0, keyIndex).toLowerCase() + key.slice(keyIndex, key.length)] = item[key];
          });
          return res;
        })
      }
    } else {
      return null;
    }
  }

  parseValidations(resp) {
    if(resp) {
      Object.keys(resp).forEach((respKey) => {
        if (resp.hasOwnProperty(respKey) && resp[respKey].hasOwnProperty("regEx")) {
          resp[respKey] = resp[respKey].regEx
        } else if(resp.hasOwnProperty(respKey)) {
          delete resp[respKey];
        }
      });
    }
    
    return resp;
  }

}

export default new Transformer();