import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Buttons/Button';
import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import Utils from 'utils/Utils';

/**
 * 
 * 
 */
export const TableSearch = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const defaultValue = new URLSearchParams(location.search).get("query");

    const [search, setSearch] = useState(defaultValue || "");

    const history = useHistory();
    const params = Utils.getBasicTableParams(location, ["query", "page"]);

    const doSearch = () => {
        if(search !== defaultValue) {
            const fullQuery = "?query=" + search + params;
            history.push(fullQuery);
            timeoutLoading();
        }
        
    }

    const timeoutLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    return <>
        <InputGroup className="table-search-input mb-4">
            <Input type="text" value={search} onKeyPress={(e) => {
                if(e.key === "Enter" && !loading) doSearch()
            }} onChange={(e) => setSearch(e.target.value)} placeholder={props.placeholder || t("common.Search")} />
            <InputGroupAddon addonType="prepend">
                <Button disabled={loading} color="primary" icon="search" onClick={doSearch}/>
            </InputGroupAddon>
        </InputGroup>
    </>
}