
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import { useToasts } from 'react-toast-notifications';
import { TOAST_TYPE } from "variables/constants";
import {LOGOUT_PATH} from "variables/constants"
import { useTranslation } from "react-i18next";

const AdminNavbar = (props) => {
  const { t } = useTranslation();
  const Blitzcrank = useBlitzcrank();

  const store = useStore();
  const { addToast } = useToasts();

  const logout = () => {
    Blitzcrank.post(LOGOUT_PATH, {}, (status, data) => {
      addToast(t("toasts.LogoutSuccess"), {appearance: TOAST_TYPE.SUCCESS});
      store.logoutUser();
    })
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <span
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            
          >
            {props.brandText}
          </span>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <i className="ni ni-circle-08" />
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {store.currentUser.email}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">{t("common.Welcome")}!</h6>
                </DropdownItem>
                <DropdownItem to={store.isUserAdmin ? "/admin/user-profile" : "/public/user-profile"} tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>{t("nav.MyProfile")}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pbl" onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>{t("nav.Logout")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
