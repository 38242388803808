
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {  ListGroup, ListGroupItem } from "reactstrap";
import useStore from "store/store";
import Cookies from "utils/Cookies";

const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();
    const store = useStore();

    const selectedLanguage = i18n.language.toLowerCase();
    const languages = ["en", "bg"];

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        store.updateLanguageCookie(lang);
    }

    return (
        <ul className="language-switcher">
            {languages.map((lang) => {
                return <li className={selectedLanguage === lang ? "active" : ""} title={lang.toUpperCase()} onClick={() => changeLanguage(lang)}>
                    <span className="chevron"><FontAwesomeIcon icon="chevron-down" /></span>
                    <img alt={"Country flag"} src={require("../../assets/img/flags/" + lang.toLowerCase() + ".gif").default} />
                </li>
            })}
        </ul>
    );
};

export default LanguageSwitcher;
