
import React from "react";
import { Container, Navbar, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";

const ErrorLayout = (props) => {
    return (
        <>
            <div className="main-content">
                <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
                    <Container className="px-4">
                        <NavbarBrand tag="a" href="/">
                            <img
                                alt="Digident logo"
                                src={
                                    require("assets/img/brand/logo.png").default
                                }
                            />
                        </NavbarBrand>
                    </Container>
                </Navbar>
                <Container style={{ paddingTop: "6.5rem" }}>
                    {props.children}
                </Container>
            </div>
        </>
    );
};

export default ErrorLayout;
