import { Button } from "components/Buttons/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import {
    Card,
    CardBody,
    Col,
    Container, FormGroup, Input, Label, Row
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { TOAST_TYPE } from "variables/constants";
import { RESET_PASSWORD_PATH } from "variables/constants";
import useSettings from "utils/Settings";
import { FormWrapper } from "components/FormGroup/FormWrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordResetSchema } from "schemas/ValidationSchemas";
import { useForm } from "react-hook-form";
import FormSubmit from "components/FormGroup/FormSubmit";
import FormInputController from "components/FormGroup/FormInputController";
import { PasswordsChangeSchema } from "schemas/ValidationSchemas";

const AuthChangePassword = (props) => {
    const Blitzcrank = useBlitzcrank();

    let history = useHistory();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { validations } = useSettings();

    const formPasswordChange = useForm({
        resolver: yupResolver(PasswordsChangeSchema(t, validations))
    });

    const { watch } = formPasswordChange;

    const newPassword = watch("newPassword");
    const confirmNewPassword = watch("confirmNewPassword");

    let matchRequired = false;
    let passwordsMatch = newPassword === confirmNewPassword;
    if (newPassword && confirmNewPassword) {
        matchRequired = true;
    }

    const confirmPasswordChange = (data) => {
        Blitzcrank.post(RESET_PASSWORD_PATH, {
            token: props.match.params.token,
            password: data.newPassword
        }, (status, data) => {
            if (status === 200) {
                addToast(t("toasts.PasswordResetSuccess", { appearance: TOAST_TYPE.SUCCESS }))
                history.push("/auth/login");
            } else {
                addToast(t("toasts.PasswordResetFailure", { appearance: TOAST_TYPE.ERROR }))
            }
        })
    }

    return (
        <>
            <Col lg="5" md="7">
                <Card>
                    <CardBody>

                        <FormWrapper form={formPasswordChange}>
                            <FormInputController
                                type="password"
                                label={t("auth.NewPassword")}
                                id={"newPassword"}
                            />

                            <FormInputController
                                type="password"
                                label={t("auth.ConfirmNewPassword")}
                                id={"confirmNewPassword"}
                            />


                            {matchRequired && <div className="text-muted font-italic">
                                <small>
                                    {t("auth.PasswordsAre")}{" "}
                                    {passwordsMatch ?
                                        <span className="text-success font-weight-700">{t("auth.Matching")}</span> :
                                        <span className="text-danger font-weight-700">{t("auth.NotMatching")}</span>}
                                </small>
                            </div>}
                            <Row className="mt-4">
                                <Col className="text-center">
                                    <FormSubmit color="primary" disabled={!passwordsMatch} onClick={confirmPasswordChange}>{t("common.Confirm")}</FormSubmit>
                                </Col>
                            </Row>
                        </FormWrapper>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default AuthChangePassword;
