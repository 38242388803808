
/*eslint-disable*/
import CookieSettingsController from "components/Cookies/CookieSettingsController";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import React from "react";

// reactstrap components
import { Row, Col, Nav, NavItem, NavLink, Container } from "reactstrap";
import useSettings from "utils/Settings";
import FooterContactUs from "./FooterContactUs";
import FooterSocialLinks from "./FooterSocialLinks";
import QuickLinks from "./QuickLinks";

const Footer = (props) => {
  const { showCurrencySwitch, showLanguageSwitch } = useSettings();
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="pt-2 justify-content-center">
          <Col xl="4" className="text-center">
            {showLanguageSwitch && <LanguageSwitcher />}
            {showCurrencySwitch && <CurrencySwitcher />}
            <br />
            <CookieSettingsController />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="4">
            <div className="copyright text-center text-xl-left text-muted">
              © {new Date().getFullYear()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="/"
                rel="noopener noreferrer"
                target="_blank"
              >
                DiGiDENT
              </a>
            </div>
          </Col>
          <Col xl="4" className="text-center pt-4">

          </Col>
          <Col xl="4">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink
                  href="https://www.vecteezy.com/free-vector/abstract"
                  target="_blank"
                >
                  Abstract Vectors by Vecteezy
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>

    </footer>
  );
};

export default Footer;
