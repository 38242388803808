import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import { APP_URL, UPLAOD_IMAGE_PATH } from "./constants";
import Blitzcrank from "requests/Blitzcrank";

export const EDITOR_JS_TOOLS = {
    embed: Embed,
    marker: Marker,
    list: List,
    warning: Warning,
    image: {
        class: Image,
        config: {
            uploader: {
                uploadByFile: async (data) => {
                    const body = new FormData();
                    body.append("image", data);
                    let res = {
                        success: 0
                    };
                    await Blitzcrank.post(UPLAOD_IMAGE_PATH, body, (status, data) => {
                        if (status === 200) {
                            data.file.url = APP_URL + data.file.url;
                            res = data;
                        }
                    })
                    return res;
                },
                //byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
            }
        }
    },
    raw: Raw,
    header: Header,
    delimiter: Delimiter,
    inlineCode: InlineCode
};