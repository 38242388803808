
import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { ADMIN_STATISTICS_PATH } from "variables/constants";
import Transformer from "transformers/Transformer";
import { useTranslation } from "react-i18next";


const Index = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const [statistics, setStatistics] = useState(null);

  const parseStatistics = (stats) => {
    stats.salesThisMonth = stats.salesTotalByMonths[new Date().getMonth()];
    stats.salesTotal = 0;
    Object.keys(stats.salesTotalByMonths).forEach((month) => {
      stats.salesTotal += stats.salesTotalByMonths[month];
    });
    return stats;
  }

  useEffect(() => {
    Blitzcrank.get(ADMIN_STATISTICS_PATH, (status, data) => {
      if(status === 200) {
        setStatistics(parseStatistics(data.statistics));
      }
    })
  }, [])

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const chartMonths = [t("date.Jan"), t("date.Feb"), t("date.Mar"), t("date.Apr"), t("date.May"), t("date.Jun"), t("date.Jul"), t("date.Aug"), t("date.Sep"), t("date.Oct"), t("date.Nov"), t("date.Dec")];
  return (
    <>
      {statistics !== null && <Header salesThisMonth={statistics.salesThisMonth} salesTotal={statistics.salesTotal} users={statistics.users} orders={statistics.orders} />}
      {/* Page content */}
      <Container className="mt-4" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" lg="12" xl="7">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      {t("common.Overview")}
                    </h6>
                    <h2 className="text-white mb-0">{t("dashboard.SalesValue")}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {statistics !== null && <Line
                    data={{
                      labels: chartMonths,
                      datasets: [
                        {
                          label: t("dashboard.Performance"),
                          data: chartMonths.map((month, i) => {
                            const hasData = statistics.salesTotalByMonths.hasOwnProperty(i);
                            return hasData ? statistics.salesTotalByMonths[i] : 0;
                          }),
                        },
                      ],
                    }}
                    options={chartExample1.options}
                  />}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5" lg="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                    {t("dashboard.Performance")}
                    </h6>
                    <h2 className="mb-0">{t("dashboard.TotalOrders")}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {statistics !== null && <Bar
                    data={{
                      labels: chartMonths,
                      datasets: [
                        {
                          label: t("dashboard.Sales"),
                          data: chartMonths.map((month, i) => {
                            const hasData = statistics.ordersByMonths.hasOwnProperty(i);
                            return hasData ? statistics.ordersByMonths[i] : 0;
                          }),
                          maxBarThickness: 10,
                        },
                      ],
                    }}
                    options={chartExample2.options}
                  />}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
