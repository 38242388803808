import { Button } from "components/Buttons/Button";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// reactstrap components
import {
  Col,
  Container
} from "reactstrap";

const ThankYouOrder = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid>
        <Col lg="12" md="12" className="text-center">
          <h1 className="mt-7">{t("common.ThankYouOrder")}</h1>
          <h3 className="text-muted mb-7">{t("common.YourOrderNumberIs")} #{props.orderNumber}.</h3>
          
          <Button icon="plus" onClick={props.onNewOrder} size="lg" color="primary">{t("nav.CreateNewOrder")}</Button>
          <Button tag={Link} to={"/"} outline color="default">{t("nav.BackToDashboard")}</Button>
        </Col>
      </Container>
    </>
  );
};

export default ThankYouOrder;
