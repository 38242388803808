import axios from 'axios';
import { APP_URL } from 'variables/constants';

class Blitzcrank {
  constructor() {
    let service = axios.create({
      baseURL: APP_URL
    });
    service.defaults.withCredentials = true
    
    this.service = service;
  }
  
  get(path, callback, config = {}) {
    return this.service.request({
      method: 'GET',
      url: path,
      responseType: config.responseType || 'json'
    }).then(
      (response) => callback(response.status, response.data)
    ).catch(
      (error) => console.log(error)
    )
  }

  patch(path, payload, callback, config = {}) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then(
      (response) => callback(response.status, response.data)
    ).catch(
      (error) => console.log(error)
    )
  }

  post(path, payload, callback, config = {}) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then(
      (response) => callback(response.status, response.data)
    ).catch(
      (error) => console.log(error)
    )
  }

  delete(path, callback, config = {}) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json'
    }).then(
      (response) => callback(response.status, response.data)
    ).catch(
      (error) => console.log(error)
    )
  }

  update(path, payload, callback, config = {}) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then(
      (response) => callback(response.status, response.data)
    ).catch(
      (error) => console.log(error)
    )
  }
}

export default new Blitzcrank();