import React from "react";

import {
    Container
} from "reactstrap";

import PrivacyPolicy from "./PrivacyPolicy";

const MaintainPrivacyPolicy = (props) => {
    return (<>
        <Container fluid>
            <PrivacyPolicy />
        </Container>
    </>);
};

export default MaintainPrivacyPolicy;
