const localURL = "http://localhost:8080";
const externalURL = process.env.REACT_APP_URL;

export const APP_URL = process.env.NODE_ENV === "production" ? externalURL : localURL;
export const PRODUCTS_PATH = "/products"
export const DISCOUNTED_PRODUCTS_PATH = "/discountedProducts"
export const REGISTER_PATH = "/register"
export const LOGIN_PATH = "/login"
export const LOGOUT_PATH = "/logout"
export const USER_SESSION_PATH = "/getSession"
export const USERS_PATH = "/users"
export const USERS_BUSINESS_PATH = "/usersBusiness"
export const USERS_ADMIN_PATH = "/usersByAdmin"
export const SHIPPING_ADDRESSES_PATH = "/shippingAddresses"
export const ORDERS_PATH = "/orders";
export const ORDERS_STATUS_UPDATE_PATH = "/ordersStatus";
export const DISCOUNTS_PATH = "/discounts";
export const ADMIN_STATISTICS_PATH = "/getStatistics";
export const CUSTOMER_STATISTICS_PATH = "/getUserStatistics";
export const UPLAOD_IMAGE_PATH = "/uploadImage";
export const SAVE_PAGE_BUILD_PATH = "/savePageBuild";
export const GET_PAGE_BUILD_PATH = "/getPageBuild";
export const ORDER_FILES_PATH = "/saveOrderFiles";
export const UPLOAD_INVOICE_PATH = "/uploadInvoice";
export const DOWNLOAD_INVOICE_PATH = "/downloadInvoice";
export const DELETE_INVOICE_PATH = "/deleteInvoice";
export const GET_INVOICES_PATH = "/invoices";
export const DOWNLOAD_ORDER_FILES_PATH = "/downloadOrderFiles";
export const FORGOTTEN_PASSWORD_PATH = "/forgottenPassword";
export const RESET_PASSWORD_PATH = "/resetPassword";
export const CHANGE_PASSWORD_PATH = "/changePassword";
export const PRODUCT_IMAGE_UPLOAD_PATH = "/productImageUpload";
export const SETTINGS_PATH = "/public/settings.json";
export const VALIDATIONS_PATH = "/public/validations.json";
export const HOMEPAGE_CAROUSEL_LIST_PATH = "/public/homepage-carousel/list.json"
export const OUR_SERVICES_LIST_PATH = "/public/our-services/list.json"
export const WHY_CHOOSE_US_LIST_PATH = "/public/why-choose-us/list.json"
export const CONTACT_US_PATH = "/contactUs";
export const SAVE_SETTINGS_JSON = "/saveSettingJson";
export const FOOTER_BANNERS_PATH = "/public/footer-banners/list.json"

export const PRIVACY_POLICY_URL = externalURL + "/privacy_policy.html";
export const COOKIE_POLICY_URL = externalURL + "/cookie_policy.html";

export const SITE_KEY = "6LeKkaMbAAAAAE9UzECVbq2oUswDy2esrMEF3iEF"

export const COOKIE_CONSENT_TYPE = {
    STRICT: 1,
    VISITORS: 2
}

export const SUPPORTED_FILE_UPLOAD_FORMATS = [".stl", ".constructioninfo", ".pts", ".rar", ".zip"];

export const COOKIE = {
    LANG: "lang",
    CURR: "currency",
    CC: "cc"
}

export const USER_ROLES = {
    CUSTOMER: 1,
    ADMIN: 2,
    MASTER: 3
}

export const USER_ROLES_TEXT = {
    [USER_ROLES.CUSTOMER]: "Customer",
    [USER_ROLES.ADMIN]: "Admin",
    [USER_ROLES.MASTER]: "Master"
}

export const ORDER_STATUS = {
    PENDING: 1,
    ACCEPTED: 2,
    COMPLETED: 3,
    CANCELLED: 0
}

export const ORDER_STATUS_TEXT = {
    [ORDER_STATUS.PENDING]: "orderStatus.Pending",
    [ORDER_STATUS.ACCEPTED]: "orderStatus.Accepted",
    [ORDER_STATUS.COMPLETED]: "orderStatus.Completed",
    [ORDER_STATUS.CANCELLED]: "orderStatus.Cancelled"
}

export const TOAST_TYPE = {
    SUCCESS: "success",
    ERROR: "warning",
    INFO: "info",
    WARNING: "warning"
}

export const PAYMENT_TYPE = {
    CASH: 1,
    BANK: 2
}

export const PAYMENT_TYPE_TEXT = {
    [PAYMENT_TYPE.CASH]: "paymentType.Cash",
    [PAYMENT_TYPE.BANK]: "paymentType.Bank"
}

export const SHIPPING_TYPE = {
    DELIVERY: 1,
    PICKUP: 2
}

export const SHIPPING_TYPE_TEXT = {
    [SHIPPING_TYPE.DELIVERY]: "common.DeliverToMyHome",
    [SHIPPING_TYPE.PICKUP]: "common.PickUpFromLab"
}


export const CURRENCIES = {
    BGN: "BGN",
    EUR: "EUR"
}

export const INPUT_CURRENCY = CURRENCIES.BGN;

export const CURRENCY_TEXT = {
    [CURRENCIES.BGN]: "currency.BGN",
    [CURRENCIES.EUR]: "currency.EUR"
}