import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Label,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
} from "reactstrap";
import FormInputController from "components/FormGroup/FormInputController";
import { FormWrapper } from "components/FormGroup/FormWrap";
import FormSubmit from "components/FormGroup/FormSubmit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { SHIPPING_ADDRESSES_PATH } from "variables/constants";
import Transformer from "transformers/Transformer";
import useStore from "store/store";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import { useTranslation } from "react-i18next";
import { Button } from "components/Buttons/Button";
import { COUNTRY_LIST } from "variables/countries";

export const ShippingAddressManager = (props) => {
    const { formShippingAddress, selectable, onSelected } = props;
    const { t } = useTranslation();
    const Blitzcrank = useBlitzcrank();
    const store = useStore();
    const { addToast } = useToasts();

    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [newShippingAddressRow, setNewShippingAddressRow] = useState(false);
    const [selectedShippingAddressId, setSelectedShippingAddressId] = useState(null);

    const shippingAddressName = formShippingAddress.watch("shippingAddressName");

    useEffect(() => {
        if (store.currentUser) {
            Blitzcrank.get(SHIPPING_ADDRESSES_PATH + "/" + store.currentUser.id, (status, data) => {
                if (status === 200) setShippingAddresses(Transformer.parse(data));
            })
        }
    }, [store.currentUser]);

    const updateShippingAddress = (newShippingAddress) => {
        const method = newShippingAddress.id ? "update" : "post";

        const payload = {
            receiver: newShippingAddress.receiver,
            phoneNumber: newShippingAddress.phoneNumber,
            addressLine: newShippingAddress.addressLine,
            postalCode: newShippingAddress.postalCode,
            city: newShippingAddress.city,
            country: newShippingAddress.country,
            shippingAddressName: newShippingAddress.shippingAddressName,
            userId: store.currentUser.id,
            id: newShippingAddress.id
        };

        const addressCallback = (status, data) => {
            if (status === 200) {
                formShippingAddress.reset();
                addToast(t("toasts.ShippingAddressSaveSuccess"), { appearance: TOAST_TYPE.SUCCESS });
                Blitzcrank.get(SHIPPING_ADDRESSES_PATH + "/" + store.currentUser.id, (status, data) => {
                    if (status === 200) setShippingAddresses(Transformer.parse(data))
                })
            } else {
                addToast(t("toasts.ShippingAddressSaveFailure"), { appearance: TOAST_TYPE.ERROR });

            }
        }

        switch (method) {
            case "post":
                Blitzcrank.post(SHIPPING_ADDRESSES_PATH, payload, addressCallback);
                cancelNewShippingAddress();
                break;
            case "update":
                Blitzcrank.update(SHIPPING_ADDRESSES_PATH, payload, addressCallback);
                cancelNewShippingAddress();
                break;
            default:
                return;
        }
    }

    const deleteShippingAddress = (address) => {
        Blitzcrank.delete(SHIPPING_ADDRESSES_PATH + "/" + address.id, (status, data) => {
            if (status === 200) {
                addToast(t("toasts.ShippingAddressDeleteSuccess"), { appearance: TOAST_TYPE.SUCCESS });
                Blitzcrank.get(SHIPPING_ADDRESSES_PATH + "/" + store.currentUser.id, (status, data) => {
                    if (status === 200) setShippingAddresses(Transformer.parse(data))
                })
            } else {
                addToast(t("toasts.ShippingAddressDeleteFailure"), { appearance: TOAST_TYPE.ERROR });
            }
        })
    }

    const addNewShippingAddressRow = (address) => {
        if (address) formShippingAddress.reset(address);
        setNewShippingAddressRow(true);
    }

    const cancelNewShippingAddress = () => {
        formShippingAddress.reset();
        setNewShippingAddressRow(false);
    }

    const onAddressSelected = (addressId) => {
        setSelectedShippingAddressId(addressId);
        if (onSelected) {
            onSelected(shippingAddresses.find((address) => address.id === addressId));
        }
    }

    return <>
        {shippingAddresses.map((shippingAddress) => {
            if (selectable) {
                const selected = shippingAddress.id === selectedShippingAddressId;
                return <ListGroupItem tag="button" action active={selected} onClick={() => onAddressSelected(shippingAddress.id)}>
                    {shippingAddress.shippingAddressName} ({shippingAddress.addressLine}, {shippingAddress.city}, {shippingAddress.postalCode})
                </ListGroupItem>
            } else {
                return <Card className="mt-2">
                    <CardHeader>{shippingAddress.shippingAddressName}</CardHeader>
                    <CardBody>
                        <p>{shippingAddress.receiver}</p>
                        <p>{shippingAddress.addressLine}, {shippingAddress.city}, {shippingAddress.postalCode}</p>
                        <Button size="sm" icon={"edit"} outline color="default"
                            onClick={() => addNewShippingAddressRow(shippingAddress)}></Button>
                        <Button size="sm" icon={"trash"} outline color="danger"
                            onClick={() => deleteShippingAddress(shippingAddress)}></Button>
                    </CardBody>
                </Card>
            }

        })}
        {newShippingAddressRow && <Card className="mt-2">
            <FormWrapper form={formShippingAddress}>
                <CardHeader>{shippingAddressName || t("common.ShippingAddressNamePlaceholder")}</CardHeader>
                <CardBody>

                    <Row>
                        <Col lg="12">
                            <FormInputController
                                type="text"
                                label={t("common.AddressNameWithDisclaimer")}
                                id={"shippingAddressName"}
                                placeholder={t("common.AddressName")}
                                name="label"
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormInputController
                                type="text"
                                label={t("common.Receiver")}
                                id={"receiver"}
                                name="Name"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6" lg="12">
                            <FormInputController
                                type="text"
                                label={t("common.AddressLine")}
                                id={"addressLine"}
                            />
                        </Col>
                        <Col xl="6" lg="12">
                            <FormInputController
                                type="tel"
                                label={t("common.PhoneNumber")}
                                id={"phoneNumber"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="4" lg="12">
                            <FormInputController
                                type="text"
                                label={t("common.City")}
                                id={"city"}
                            />
                        </Col>
                        <Col xl="4" lg="12">
                            <FormInputController
                                type="select"
                                label={t("common.Country")}
                                id={"country"}
                            >
                                {COUNTRY_LIST.map((country) => {
                                    return <option value={country.code}>{country.name}</option>
                                })}
                            </FormInputController>
                        </Col>
                        <Col xl="4" lg="12">
                            <FormInputController
                                type="text"
                                label={t("common.PostalCode")}
                                id={"postalCode"}
                            />
                        </Col>
                    </Row>
                    <FormSubmit color="success"
                        onClick={updateShippingAddress}>{t("common.Save")}</FormSubmit>
                    <Button outline color="default"
                        onClick={cancelNewShippingAddress}>{t("common.Cancel")}</Button>

                </CardBody>
            </FormWrapper>
        </Card>}
        {!newShippingAddressRow && <Button icon="plus" outline color="primary" className="mt-2"
            onClick={() => addNewShippingAddressRow(null)}>{t("nav.NewShippingAddress")}</Button>}
    </>
}