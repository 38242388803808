import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardHeader,
  Badge,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  Alert,
  UncontrolledTooltip
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import Transformer from "transformers/Transformer";
import { PRODUCTS_PATH } from "variables/constants";
import { Button } from "components/Buttons/Button";
import Checkout from "./Checkout";
import ThankYouOrder from "./ThankYouOrder";
import { APP_URL } from "variables/constants";
import { useTranslation } from "react-i18next";
import useStore from "store/store";
import { DISCOUNTED_PRODUCTS_PATH } from "variables/constants";
import usePricing from "utils/Pricing";
import { SUPPORTED_FILE_UPLOAD_FORMATS } from "variables/constants";
import Utils from "utils/Utils";


const CreateOrder = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const pricing = usePricing();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showCheckoutDetails, setShowCheckoutDetails] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderComment, setOrderComment] = useState("");

  useEffect(() => {
    Blitzcrank.get(DISCOUNTED_PRODUCTS_PATH, (status, data) => {
      if (status === 200) {
        const parsedData = Transformer.parse(data.products)
        setProducts(parsedData);
      }
    })
  }, []);

  const onQuantityChange = (e) => {
    setQuantity(e.target.value);
  }

  const onFileSelected = (e) => {
    const files = e.target.files;
    setUploadedFiles([...uploadedFiles, ...files]);
  }

  const removeFile = (index) => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setUploadedFiles(files);
  }

  const proceedToCheckoutDisabled = uploadedFiles.length === 0 || !quantity || quantity <= 0;

  const proceedToCheckout = () => {
    if (!proceedToCheckoutDisabled) setShowCheckoutDetails(true);
  }

  return (
    <>
      {orderNumber === null ? <Container fluid>
        {selectedProduct === null ? <Row>
          <Col lg="12">
            <h2 className="text-muted mb-4">{t("common.SelectProduct")}</h2>

            <Row>
              {Utils.sortNumeric(products, "orderInList").map((product, i) => {
                return <Col xl="3" lg="4" md="6" sm="12" className="p-1"><Card className="user-product-card" key={"SP_" + product.id}>
                  <CardImg className="product-img" top src={APP_URL + product.image} alt="Product image cap" />
                  <CardBody>
                    <CardTitle tag="h4">{product.title}</CardTitle>
                    <CardSubtitle>
                      {product.discount ?
                        <p>
                          <span className="text-through text-muted"><small>{pricing.format(product.price)}</small></span>&nbsp;
                          <span><b>{pricing.calculate(product.price, 1, product.discount)}</b></span>
                        </p> :
                        <p>{pricing.calculate(product.price, 1)}</p>}
                    </CardSubtitle>
                    <div className="select-product-button-wrapper">
                      <Button style={{ width: "100%" }} color="primary" onClick={() => setSelectedProduct(product)} icon={'chevron-right'}>{t("common.Select")}</Button>
                    </div>
                  </CardBody>
                </Card></Col>
              })}

            </Row>

          </Col>
        </Row> : <Row>
          <Col lg="12">
            <h2 className="text-muted mb-4">{t("common.ProductDetails")}</h2>
            <Row>
              <Col md="4">
                <Card style={{ height: "100%" }}>
                  <CardImg top className="product-img" style={{height: "350px"}} src={APP_URL + selectedProduct.image} />
                  <CardBody>
                    <CardTitle>{selectedProduct.title}</CardTitle>
                    <p>{selectedProduct.description}</p>
                    {selectedProduct.discount ?
                      <p>
                        <span className="text-through text-muted"><small>{pricing.calculate(selectedProduct.price, quantity)}</small></span>&nbsp;
                        <span><b>{pricing.calculate(selectedProduct.price, quantity, selectedProduct.discount)}</b></span>
                      </p> :
                      <p>{pricing.calculate(selectedProduct.price, quantity)}</p>}

                    <Alert color="info">
                      {t("common.PriceQuantityAlert")}
                    </Alert>
                    <FormGroup>
                      <Label>{t("common.Quantity")}</Label>
                      <Input type="number" onChange={onQuantityChange} value={quantity} />
                    </FormGroup>


                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Card style={{ height: "100%" }}>
                  <CardHeader>{t("common.UploadFiles")}</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label>File {SUPPORTED_FILE_UPLOAD_FORMATS.map((format) => {
                        return <Badge className={"mr-1"} color="info">{format}</Badge>
                      })}</Label>
                      <Input onChange={onFileSelected} accept={SUPPORTED_FILE_UPLOAD_FORMATS.join(",")} multiple type="file" />
                    </FormGroup>

                    <ListGroup>
                      {uploadedFiles.map((file, index) => {
                        const fileExtension = file.name.split(".")[1];
                        return <ListGroupItem>
                          <Button className="mr-2" onClick={() => removeFile(index)} outline color="danger" icon="trash" size="sm"></Button>
                          {file.name}
                          {(!fileExtension || SUPPORTED_FILE_UPLOAD_FORMATS.indexOf("." + fileExtension.toLowerCase()) === -1) && <Badge className="ml-1" color="danger">{t("common.WrongFileExtension")}</Badge>}
                        </ListGroupItem>
                      })}
                    </ListGroup>

                    <FormGroup className="mt-2">
                      <Label>{t("common.Comment")}</Label>
                      <Input type="textarea" onChange={(e) => setOrderComment(e.target.value)} value={orderComment} />
                    </FormGroup>

                    {!showCheckoutDetails && <>
                      {proceedToCheckoutDisabled && <UncontrolledTooltip placement="top" target="proceedToCheckoutBtn">
                        {t("common.DisabledCheckoutTooltip")}
                      </UncontrolledTooltip>}
                      <Button id="proceedToCheckoutBtn" size="lg" onClick={proceedToCheckout} color="primary">{t("nav.ProceedToCheckout")}</Button>
                      <Button onClick={() => setSelectedProduct(null)} outline color="default">{t("common.Cancel")}</Button>
                    </>}

                  </CardBody>
                </Card>
              </Col>
            </Row>

            {showCheckoutDetails && <Row className="mt-4">
              <Col md="12">
                <Card>
                  <CardBody>
                    <Checkout
                      totalPrice={pricing.calculateDouble(selectedProduct.price, quantity, selectedProduct.discount)}
                      quantity={quantity} product={selectedProduct}
                      comment={orderComment}
                      files={uploadedFiles}
                      onPlaceOrder={(orderNumber) => setOrderNumber(orderNumber)}
                      onCancelOrder={() => setShowCheckoutDetails(false)}
                    />
                  </CardBody>
                </Card>

              </Col>
            </Row>}

          </Col>
        </Row>}

      </Container> : <ThankYouOrder onNewOrder={() => {
        setOrderNumber(null);
        setOrderComment("");
        setQuantity(1)
        setSelectedProduct(null);
        setUploadedFiles([]);
      }} orderNumber={orderNumber} />}
    </>
  );
};

export default CreateOrder;
