import { Button } from "components/Buttons/Button";
import { Paginator } from "components/Pagination/Paginator";
import { TableHeaderSortable } from "components/Tables/TableHeaderSortable";
import { TableSearch } from "components/Tables/TableSearch";
import React, { useEffect, useState } from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
    Col,
    Container, Input, Row, Table
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import Transformer from "transformers/Transformer";
import { DISCOUNTS_PATH } from "variables/constants";
import { PRODUCTS_PATH } from "variables/constants";
import { USERS_PATH } from "variables/constants";
import { useTranslation } from "react-i18next";
import usePricing from "utils/Pricing";
import { useToasts } from "react-toast-notifications";
import { TOAST_TYPE } from "variables/constants";
import Utils from "utils/Utils";
import { useLocation } from "react-router-dom";


const Discounts = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const pricing = usePricing();
    const store = useStore();
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [discounts, setDiscounts] = useState({});
    const [totalOrderRows, setTotalOrderRows] = useState(0);
    const { addToast } = useToasts();
    const location = useLocation();


    useEffect(() => {
        if (store.isUserLogged) {
            Blitzcrank.get(USERS_PATH + Utils.getBasicTableParams(location), (status, data) => {
                if (status === 200) {
                    setUsers(Transformer.parse(data.rows));
                    setTotalOrderRows(data.totalRows);
                }
            });
            Blitzcrank.get(PRODUCTS_PATH, (status, data) => {
                if (status === 200) {
                    const prodData = Transformer.parse(data.products);
                    setProducts(prodData);
                }
            })

        }
    }, [store.isUserLogged, location]);

    const selectUser = (user) => {
        Blitzcrank.get(DISCOUNTS_PATH + "/" + user.id, (status, data) => {
            if (status === 200) {
                setDiscounts(createDiscountsMap(Transformer.parse(data)));
                setSelectedUser(user);
            }
        })
    }

    const createDiscountsMap = (arr) => {
        const res = {};
        arr.forEach((disc) => {
            res[disc.productID] = disc.percentageValue
        })
        return res;
    }

    const onProductDiscountChange = (prop, val) => {
        const newDiscounts = { ...discounts };
        newDiscounts[prop] = val;
        setDiscounts(newDiscounts);
    }

    const deleteAllDiscounts = () => {
        saveAllDiscounts(true);
    }

    const saveAllDiscounts = (clearAll) => {
        let hasInvalidDiscounts = false;
        const discountsArr = clearAll ? [] : Object.keys(discounts).map((key) => {
            return {
                productId: key,
                percentageValue: discounts[key]
            }
        }).filter((discount) => {
            if (isInvalidDiscount(discount.percentageValue)) {
                hasInvalidDiscounts = true;
                return false;
            } else if(discount.percentageValue && discount.percentageValue > 0) {
                return true;
            } else {
                return false;
            }
        });

        if (!hasInvalidDiscounts) {
            Blitzcrank.post(DISCOUNTS_PATH, {
                userId: selectedUser.id,
                discounts: discountsArr
            }, (status, data) => {
                if (status === 200) {
                    addToast(t("toasts.DiscountsSaveSuccess"), { appearance: TOAST_TYPE.SUCCESS });
                    selectUser(selectedUser)
                } else {
                    addToast(t("toasts.DiscountsSaveFailure"), { appearance: TOAST_TYPE.ERROR });
                }
            })
        } else {
            addToast(t("toasts.InvalidDiscounts"), { appearance: TOAST_TYPE.ERROR });
        }

    }

    const isInvalidDiscount = (discount) => {
        return (discount && isNaN(discount)) || discount > 100
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <CardTitle>
                                            {t("common.Users")}
                                        </CardTitle>
                                    </Col>
                                </Row>


                                <TableSearch
                                    placeholder={t("common.Search") + " (" + [t("common.Email"), t("common.Name")].join(", ") + ")"}
                                    endpoint={USERS_PATH}
                                />

                                <Table responsive className="mt-4">
                                    <TableHeaderSortable
                                        headers={[
                                            {
                                                text: t("common.Email")
                                            },
                                            {
                                                text: t("common.FirstName")
                                            },
                                            {
                                                text: t("common.LastName")
                                            },
                                            {
                                                text: ""
                                            }
                                        ]}
                                    />
                                    <tbody>
                                        {users.map((user) => {
                                            return <tr key={"usr_"+user.email}>
                                                <td>{user.email}</td>
                                                <td>{user.firstName}</td>
                                                <td>{user.lastName}</td>
                                                <td><Button onClick={() => selectUser(user)} outline color="info" size="sm">{t("common.Manage")}</Button></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>

                                <Row>
                                    <Col>
                                        <Paginator
                                            totalRows={totalOrderRows} rightAligned />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>


                    </Col>
                    {selectedUser && <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <CardTitle>
                                            {t("common.ManageDiscountsFor")} {selectedUser.firstName} {selectedUser.lastName}
                                        </CardTitle>
                                    </Col>
                                    <Col className="text-right">
                                        <Button onClick={deleteAllDiscounts} outline color="danger" size="sm">{t("common.DeleteAllDiscounts")}</Button>
                                    </Col>
                                </Row>

                                <Table responsive className="mt-4 mb-4">
                                    <thead>
                                        <tr>
                                            <th>{t("common.Title")}</th>
                                            <th>{t("common.Price")}</th>
                                            <th>{t("common.Discount")} %</th>
                                            <th>{t("common.DiscountedPrice")}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products && products.map((product) => {
                                            const key = product.id;
                                            const discount = discounts[key];
                                            const isInvalid = isInvalidDiscount(discount)
                                            return <tr key={"prd_"+product.id}>
                                                <td>{product.title}</td>
                                                <td>{pricing.format(product.price)}</td>
                                                <td><Input invalid={isInvalid} type="text" onChange={(e) => onProductDiscountChange(key, e.target.value)} size="sm" value={discount || ""} /></td>
                                                <td>{isInvalid ? pricing.format(product.price) : pricing.calculate(product.price, 1, discount)}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>

                                <Row>
                                    <Col className="text-right">
                                        <Button onClick={() => saveAllDiscounts()} color="success" size="sm">{t("common.Save")}</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>}
                </Row>
            </Container>
        </>
    );
};

export default Discounts;
