import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import useFormWrap from "./FormWrap";

const FormInputController = (props) => {
    const { id, label, defaultValue, children } = props;
    const { form } = useFormWrap();
    const { control, formState: { errors } } = form;
    return <>
        <FormGroup>
            <Label for={id}>{label}</Label>
            <Controller
                render={({ field }) => <Input
                    id={id}
                    placeholder={label}
                    {...props}
                    {...field}
                    invalid={errors.hasOwnProperty(id) || props.invalid}
                >{children}</Input>}
                name={id}
                control={control}
                defaultValue={defaultValue || ""}
            />
            <FormFeedback>{errors[id]?.message || props.message}</FormFeedback>
        </FormGroup>


    </>
}

export default FormInputController;