import React from "react";

import {
    Container, Row
} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

const ErrorView = (props) => {
    return (
        <>
            <div className="main-content customer-layout" >
                <AuthNavbar />
                <div className="header py-7 py-lg-8">

                </div>
                {/* Page content */}
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Container fluid>
                            <h1>We ran into an unexpected error. Sorry for the inconvinience.</h1>
                        </Container>
                    </Row>
                </Container>
            </div>
            <AuthFooter />
        </>
    );
};

export default ErrorView;
