
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {  ListGroup, ListGroupItem } from "reactstrap";
import useStore from "store/store";
import Cookies from "utils/Cookies";

const CurrencySwitcher = () => {
    const { t } = useTranslation();
    const store = useStore();

    const selectedCurrency = store.currency;
    const currencies = ["EUR", "BGN"];

    const changeCurrency = (curr) => {
        store.changeCurrency(curr);
    }

    return (
        <ul className="currency-switcher">
            {currencies.map((curr) => {
                return <li className={selectedCurrency === curr ? "active" : ""} title={curr.toUpperCase()} onClick={() => changeCurrency(curr)}>
                    <span className="chevron"><FontAwesomeIcon icon="chevron-down" /></span>
                    <span className="currency-text">{curr.toUpperCase()}</span>
                </li>
            })}
        </ul>
    );
};

export default CurrencySwitcher;
