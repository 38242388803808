
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { useToasts } from 'react-toast-notifications';
import { TOAST_TYPE, SITE_KEY } from "variables/constants";
import { REGISTER_PATH } from "variables/constants";
import { Link, useHistory } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import useSettings from "utils/Settings";
import FormInputController from "components/FormGroup/FormInputController";
import { UserProfileSchema } from "schemas/ValidationSchemas";
import { FormWrapper } from "components/FormGroup/FormWrap";
import FormSubmit from "components/FormGroup/FormSubmit";
import { COUNTRY_LIST } from "variables/countries";

const Register = () => {
  const { validations } = useSettings();
  const { t } = useTranslation();
  const Blitzcrank = useBlitzcrank();

  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const formProfile = useForm({
    resolver: yupResolver(UserProfileSchema(t, validations, false))
  });

  const password = formProfile.watch("password");
  const repeatedPassword = formProfile.watch("repeatedPassword");

  const history = useHistory();

  const { addToast } = useToasts();

  const registerAccount = async (data) => {
    if (privacyPolicyChecked) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(token => {
          Blitzcrank.post(REGISTER_PATH, {
            isBusinessUser: false,
            token,
            ...data
          }, (status, data) => {
            if (status === 200) {
              addToast(t("toasts.SelfRegisterSuccess"), {appearance: TOAST_TYPE.SUCCESS});
              history.push("/auth/login");
            } else {
              addToast(t("toasts.SelfRegisterFailure"), {appearance: TOAST_TYPE.ERROR})
            }
          })
        })
      })
    }


  }

  const togglePrivacyPolicyModal = () => {
    setPrivacyPolicyModal(!privacyPolicyModal);
  }
  return (
    <>
      <Col lg="8" md="10">
        <Card className="bg-secondary shadow border-0">
          <FormWrapper form={formProfile}>
            <CardBody className="px-lg-5 py-lg-5">
              <Row>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="text"
                    label={t("common.FirstName")}
                    id={"firstName"}
                  />
                </Col>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="text"
                    label={t("common.LastName")}
                    id={"lastName"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="email"
                    label={t("common.Email")}
                    id={"email"}
                  />
                </Col>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="tel"
                    label={t("common.PhoneNumber")}
                    id={"phoneNumber"}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="password"
                    label={t("common.Password")}
                    id={"password"}
                    invalid={repeatedPassword && password !== repeatedPassword}
                    valid={repeatedPassword && password === repeatedPassword}
                  />
                </Col>
                <Col lg="6" sm="12">
                  <FormInputController
                    type="password"
                    label={t("common.RepeatPassword")}
                    id={"repeatedPassword"}
                    invalid={repeatedPassword && password !== repeatedPassword}
                    valid={repeatedPassword && password === repeatedPassword}
                  />
                </Col>
              </Row>

              <Row className="my-4">
                <Col xs="12">
                  <FormGroup check>
                    <Label check>
                      <Input onChange={(e) => setPrivacyPolicyChecked(e.target.checked)} checked={privacyPolicyChecked} type="checkbox" />{' '}
                      <span>{t("common.PrivacyPolicyAgreePretext")}{" "}</span>
                      <Button style={{ fontSize: "1rem", padding: "0", marginBottom: "3px" }} color="link" onClick={togglePrivacyPolicyModal}>
                        {t("common.PrivacyPolicyAgreePosttext")}
                      </Button>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-center">
                {!privacyPolicyChecked && <UncontrolledTooltip placement="top" target="registerBtn">
                  {t("common.PrivacyPolicyNotChecked")}
                </UncontrolledTooltip>}
                <FormSubmit id="registerBtn" className="mt-4" color="primary" onClick={registerAccount} type="button">
                  {t("common.CreateAccount")}
                </FormSubmit>
              </div>
            </CardBody>
          </FormWrapper>

        </Card>
      </Col>

      <Modal size="xl" isOpen={privacyPolicyModal} toggle={togglePrivacyPolicyModal}>
        <ModalHeader toggle={togglePrivacyPolicyModal}>{t("nav.PrivacyPolicy")}</ModalHeader>
        <ModalBody>
          <PrivacyPolicy />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePrivacyPolicyModal}>{t("common.Cancel")}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Register;
