
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import { TOAST_TYPE } from "variables/constants";
import { LOGOUT_PATH } from "variables/constants"
import { useToasts } from 'react-toast-notifications';
import { Button } from "components/Buttons/Button";
import { useTranslation } from "react-i18next";

const AdminNavbar = (props) => {
  const { t } = useTranslation();
  const Blitzcrank = useBlitzcrank();

  const { sticky, smallerWhenSticked } = props;
  const store = useStore();
  const history = useHistory();
  const { addToast } = useToasts();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if(smallerWhenSticked) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [smallerWhenSticked]);

  useEffect(() => {
    document.body.classList.add("sticky-header")
    return () => {
      document.body.classList.remove("sticky-header")
    }
  }, [sticky])

  const logout = () => {
    Blitzcrank.post(LOGOUT_PATH, {}, (status, data) => {
      addToast(t("toasts.LogoutSuccess"), { appearance: TOAST_TYPE.SUCCESS });
      store.logoutUser();
    })
  }

  const additionalClasses = [];
  if(sticky) additionalClasses.push("sticky");
  if(scrollPosition > 500) additionalClasses.push("smaller-nav");

  return (
    <>
      <Navbar className={"navbar-top navbar-horizontal navbar-dark " + additionalClasses.join(" ")} expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="DigiDent logo"
              src={
                require("../../assets/img/brand/logo.png").default
              }
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="Digident Logo"
                      src={
                        require("../../assets/img/brand/logo.png")
                          .default
                      }
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              {store.isUserAdmin && <NavItem>
                <NavLink className="nav-link-icon" to="/admin/index" tag={Link}>
                  <i className="ni ni-circle-08" />
                  <span className="nav-link-inner--text">Admin</span>
                </NavLink>
              </NavItem>}
              {store.isUserLogged && <NavItem>
                <NavLink className="nav-link-icon" to="/public/index" tag={Link}>
                  <i className="ni ni-tv-2" />
                  <span className="nav-link-inner--text">{t("nav.Dashboard")}</span>
                </NavLink>
              </NavItem>}
              {!store.isUserLogged && <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/auth/register"
                  tag={Link}
                >
                  <i className="ni ni-circle-08" />
                  <span className="nav-link-inner--text">{t("nav.Register")}</span>
                </NavLink>
              </NavItem>}
              {!store.isUserLogged && <NavItem>
                <NavLink className="nav-link-icon" to="/auth/login" tag={Link}>
                  <i className="ni ni-key-25" />
                  <span className="nav-link-inner--text">{t("nav.Login")}</span>
                </NavLink>
              </NavItem>}
              {store.isUserLogged && <UncontrolledDropdown style={{ lineHeight: "1.75" }} nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        <i className="ni ni-circle-08" />
                        {store.currentUser.fullName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{t("common.Welcome")}!</h6>
                  </DropdownItem>
                  <DropdownItem to="/public/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>{t("nav.MyProfile")}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={logout}>
                    <i className="ni ni-user-run" />
                    <span>{t("nav.Logout")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>}
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
