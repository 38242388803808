import { Button } from "components/Buttons/Button";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import {TOAST_TYPE, USERS_BUSINESS_PATH} from "variables/constants";
import { USERS_PATH } from "variables/constants";
import ChangePassword from "./ChangePassword";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfileSchema } from "schemas/ValidationSchemas";
import useSettings from "utils/Settings";
import FormInputController from "components/FormGroup/FormInputController";
import { FormWrapper } from "components/FormGroup/FormWrap";
import FormSubmit from "components/FormGroup/FormSubmit";
import { ShippingAddressSchema } from "schemas/ValidationSchemas";
import { ShippingAddressManager } from "components/ShippingAddresses/ShippingAddressManager";
import { COUNTRY_LIST } from "variables/countries";
import { BusinessProfileSchema } from "schemas/ValidationSchemas";

// core components

const UserProfile = () => {
    const Blitzcrank = useBlitzcrank();
    const { validations } = useSettings();
    const { t } = useTranslation();

    const store = useStore();
    const { addToast } = useToasts();

    const [changePasswordView, setChangePasswordView] = useState(false);
    const [isBusinessUser, setIsBusinessUser] = useState(true);
    const [addNewBusinessProfile, setAddNewBusinessProfile] = useState(false);

    const formUserProfile = useForm({
        resolver: yupResolver(UserProfileSchema(t, validations, true))
    });

    const formBusinessProfile = useForm({
        resolver: yupResolver(BusinessProfileSchema(t, validations)),
        defaultValues: {
            businessName: "",
            bulstat: "",
            mol: "",
            businessAddressLine: "",
            businessCity: "",
            businessCountry: "BG",
            businessPostalCode: "",
            businessPhoneNumber: ""
        }
    });

    const formShippingAddress = useForm({
        resolver: yupResolver(ShippingAddressSchema(t, validations)),
        defaultValues: {
            receiver: "",
            phoneNumber: "",
            addressLine: "",
            postalCode: "",
            city: "",
            country: "BG",
            shippingAddressName: ""
        }
    });

    useEffect(() => {
        if (store.currentUser) {
            Blitzcrank.get(USERS_PATH + "/" + store.currentUser.id, (status, data) => {
                if (status === 200) {
                    formUserProfile.reset(data);
                    setIsBusinessUser(data.isBusinessUser > 0);
                    if (data.isBusinessUser > 0) formBusinessProfile.reset(data);
                }
            });
        }
    }, [store.currentUser]);

    const saveUserInformation = (data) => {
        if (Object.keys(formUserProfile.formState.touchedFields).length > 0) {
            Blitzcrank.update(USERS_PATH, {
                id: store.currentUser.id,
                ...data
            }, (status, data) => {
                if (status === 200) {
                    window.location.reload();
                } else {
                    addToast(t("toasts.UserUpdateFailure"), { appearance: TOAST_TYPE.ERROR });
                }
            })
        }
    }

    const saveBusinessProfile = (data) => {
        if (Object.keys(formBusinessProfile.formState.touchedFields).length > 0) {
            Blitzcrank.update(USERS_BUSINESS_PATH, {
                id: store.currentUser.id,
                ...data
            }, (status, data) => {
                if (status === 200) {
                    window.location.reload();
                } else {
                    addToast(t("toasts.UserUpdateFailure"), { appearance: TOAST_TYPE.ERROR });
                }
            })
        }
    }


    return (
        <>
            {/* Page content */}
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="order-xl-1" lg="8" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{t("nav.MyProfile")}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            {<CardBody>
                                <h6 className="heading-small text-muted mb-4">
                                    {t("common.UserInformation")}
                                </h6>
                                <FormWrapper form={formUserProfile}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg="6">
                                                    <FormInputController
                                                        type="email"
                                                        label={t("common.Email")}
                                                        id={"email"}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col md="6">
                                                    <FormInputController
                                                        type="tel"
                                                        label={t("common.PhoneNumber")}
                                                        id={"phoneNumber"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormInputController
                                                        type="text"
                                                        label={t("common.FirstName")}
                                                        id={"firstName"}
                                                    />
                                                </Col>
                                                <Col lg="6">
                                                    <FormInputController
                                                        type="text"
                                                        label={t("common.LastName")}
                                                        id={"lastName"}
                                                    />
                                                </Col>
                                            </Row>

                                            <FormSubmit color="success" onClick={saveUserInformation}>{t("common.Save")}</FormSubmit>
                                        </CardBody>
                                    </Card>
                                </FormWrapper>

                                <hr className="my-4" />
                                <h6 className="heading-small text-muted mb-4">
                                    {t("common.BusinessDetails")}
                                </h6>

                                {isBusinessUser || addNewBusinessProfile ? <FormWrapper form={formBusinessProfile}><Card className="mt-2">
                                    <CardBody>
                                        <Row>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.BusinessName")}
                                                    id={"businessName"}
                                                />
                                            </Col>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.Bulstat")}
                                                    id={"bulstat"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.Mol")}
                                                    id={"mol"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.AddressLine")}
                                                    id={"businessAddressLine"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.PostalCode")}
                                                    id={"businessPostalCode"}
                                                />
                                            </Col>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="text"
                                                    label={t("common.City")}
                                                    id={"businessCity"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="tel"
                                                    label={t("common.BusinessPhone")}
                                                    id={"businessPhoneNumber"}
                                                />
                                            </Col>
                                            <Col lg="6" sm="12">
                                                <FormInputController
                                                    type="select"
                                                    label={t("common.Country")}
                                                    id={"businessCountry"}
                                                >
                                                    {COUNTRY_LIST.map((country) => {
                                                        return <option value={country.code}>{country.name}</option>
                                                    })}
                                                </FormInputController>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormSubmit color="success" onClick={saveBusinessProfile}>{t("common.Save")}</FormSubmit>
                                                {!isBusinessUser && <Button onClick={() => setAddNewBusinessProfile(false)} outline color="default">{t("common.Close")}</Button>}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card></FormWrapper> : <Button icon={"plus"} outline color="primary" onClick={() => setAddNewBusinessProfile(true)}>{t("common.AddBusinessProfile")}</Button>}

                                <hr className="my-4" />
                                {/* Address */}
                                <h6 className="heading-small text-muted mb-4">
                                    {t("common.ShippingAddresses")}
                                </h6>

                                <ShippingAddressManager formShippingAddress={formShippingAddress}
                                />

                                <hr className="my-4" />
                                {/* Passwords */}
                                <h6 className="heading-small text-muted mb-4">
                                    {t("auth.ManagePasswords")}
                                </h6>
                                {!changePasswordView ? <Button onClick={() => setChangePasswordView(true)} outline
                                    color="primary">{t("nav.ChangePassword")}</Button> :
                                    <Row>
                                        <Col lg="12">
                                            <Card>
                                                <CardBody>
                                                    <ChangePassword onSubmit={() => setChangePasswordView(false)}
                                                        onCancel={() => setChangePasswordView(false)} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>}
                            </CardBody>}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default UserProfile;
