
/*eslint-disable*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettings from "utils/Settings";
import { APP_URL } from "variables/constants";
import { Row, Col } from "reactstrap";

const PreFooterBanner = (props) => {
    const { t } = useTranslation();
    const { footerBanners } = useSettings();

    return (
        <>
            <div className={"pre-footer-banner show-desktop"}>
                {footerBanners.map((banner) => {
                    return <img title={banner.link} style={{position: "relative", cursor: "pointer", objectFit: banner.objectFit || "initial"}} className="mt-2" onClick={() => {
                        if(banner.link) window.open(banner.link, banner.linkTarget || "_blank");
                    }} src={banner.src} width={banner.width || "100%"} height={banner.height || "auto"} />
                })}
            </div>
        </>
    );
};

export default PreFooterBanner;
