import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BrowseLayout from "layouts/Browse";
import ErrorLayout from "layouts/Error";
import React from "react";
import { Alert, Button, Col, Row } from "reactstrap";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorLayout>
                    <Row className="mt-3">
                        <Col>
                            <Alert color="info">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                fontSize: "3rem",
                                                paddingRight: "30px",
                                                borderRight: "1px solid #fff"
                                            }}>
                                            <FontAwesomeIcon icon="tools" />
                                            </td>
                                            <td style={{
                                                paddingLeft: "20px"
                                            }}>
                                                <h4 className="alert-heading">We ran into an unexpected error.</h4>
                                                <p className="mb-0">
                                                    Sorry for the inconvinience. We will look into the issue right away.
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => window.location.href = "/"} outline color="default">Back to home</Button>
                        </Col>
                    </Row>
                </ErrorLayout>
            );
        }

        return this.props.children;
    }
}