import { Button } from 'components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Utils from 'utils/Utils';
import { ThSort } from './ThSort';

/**
 * 
 * 
 */
export const TableHeaderSortable = (props) => {
    
    const location = useLocation();
    const defaultColumn = new URLSearchParams(location.search).get("column");
    const defaultSort = new URLSearchParams(location.search).get("sort");
    const [sortedBy, setSortedBy] = useState(defaultColumn || "");
    const [sortOrder, setSortOrder] = useState(defaultSort || "");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const params = Utils.getBasicTableParams(location, ["sort", "column"]);

    const doSort = (headerId) => {
        let newSortOrder = "ASC";
        if(sortedBy === headerId && sortOrder === "ASC") {
            newSortOrder = "DESC";
        }
        setSortOrder(newSortOrder);
        setSortedBy(headerId);
        const fullQuery = "?sort=" + newSortOrder + "&column=" + headerId + params;
        history.push(fullQuery);
        timeoutLoading();
    }

    const timeoutLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    return <>
        <thead>
            <tr>
                {props.headers.map((header) => {
                    let sortIcon = "sort";
                    if(sortedBy === header.id) {
                        sortIcon = sortOrder === "ASC" ? "sort-up" : "sort-down";
                    }
                    return <th>{header.text} {header.sortable && <Button disabled={loading} className="table-sort-button" size='sm' onClick={() => doSort(header.id)} icon={sortIcon} />}</th>
                })}
            </tr>
        </thead>
    </>
}