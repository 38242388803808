import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { APP_URL } from "variables/constants";

const MultiCarousel = (props) => {
    const CarouselRef = useRef(null);
    const [items, setItems] = useState([]);
    const {t} = useTranslation();


    useEffect(() => {
        setItems(props.items);
    }, [props.items])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const onNavItemClick = (index) => {
        const deviceType = CarouselRef.current.state.deviceType;
        if(deviceType === "desktop" || deviceType === "superLargeDesktop") {
            CarouselRef.current.goToSlide(index-1);
        } else {
            CarouselRef.current.goToSlide(index);
        }
        
        if (props.onNavItemChange) props.onNavItemChange(index);
    }

    return <Carousel
        ref={CarouselRef}
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        centerMode={true}
        removeArrowOnDeviceType={["mobile", "tablet"]}
    >
        {items.map((item, i) => {
            return <div onClick={() => onNavItemClick(i)} className={"multi-carousel-slide-item text-center " + (props.activeIndex === i && "active")}>
                <span className="slide-content">
                    <img alt="Service Item" src={APP_URL + item.image} />
                    <span className="slide-caption-text">{t(item.title)}</span>
                </span>
            </div>
        })}
    </Carousel>;
}

export default MultiCarousel;