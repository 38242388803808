import React, { useEffect, useRef, useState } from "react";

import {
  ButtonGroup,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "variables/editorjs_const";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import { GET_PAGE_BUILD_PATH } from "variables/constants";
import { SAVE_PAGE_BUILD_PATH } from "variables/constants";
import { useToasts } from 'react-toast-notifications';
import { TOAST_TYPE } from "variables/constants";
import { useLocation, Prompt } from "react-router-dom";
import { Button } from "components/Buttons/Button";
import { useTranslation } from "react-i18next";


const PageBuilder = (props) => {
  const Blitzcrank = useBlitzcrank();

  const { t } = useTranslation();
  const [pageBuild, setPageBuild] = useState();
  const templates = [
    {
      id: "our-services_bg",
      title: "(BG) Our Services"
    },
    {
      id: "our-services_en",
      title: "(EN) Our Services"
    }
  ];

  const [selectedPageTemplate, selectPageTemplate] = useState(templates[0].id);
  const [hasChanges, setHasChanges] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { addToast } = useToasts();

  const editorRef = useRef();

  const getTemplatePath = (path) => {
    path = path ? path : selectedPageTemplate;
    return "./public/templates/" + path + "_template.json"
  }

  const getPublicPath = (path) => {
    path = path ? path : selectedPageTemplate;
    return "./public/templates/" + path + ".json";
  }

  const handleSave = async (path) => {
    setHasChanges(false);
    const savedData = await editorRef.current.instance.save();
    Blitzcrank.post(SAVE_PAGE_BUILD_PATH, {
      path: path,
      json: savedData
    }, (status, data) => {
      if (status === 200) {
        addToast("Page build saved successfully!", { appearance: TOAST_TYPE.SUCCESS });
      } else {
        addToast("Page build was not saved correctly!", { appearance: TOAST_TYPE.ERROR });
      }
    })
  }

  const publish = async () => {
    const savedData = await editorRef.current.instance.save();

    if (hasChanges) {
      setHasChanges(false);
      Blitzcrank.post(SAVE_PAGE_BUILD_PATH, {
        path: getTemplatePath(),
        json: savedData
      }, (status, data) => {
        if (status === 200) {
          addToast("Page build saved successfully!", { appearance: TOAST_TYPE.SUCCESS });
        } else {
          addToast("Page build was not saved correctly!", { appearance: TOAST_TYPE.ERROR });
        }
      })
    }


    Blitzcrank.post(SAVE_PAGE_BUILD_PATH, {
      path: getPublicPath(),
      json: savedData
    }, (status, data) => {
      if (status === 200) {
        addToast("Page build published successfully!", { appearance: TOAST_TYPE.SUCCESS });
      } else {
        addToast("Page build was not published correctly!", { appearance: TOAST_TYPE.ERROR });
      }
    })


  }

  const revertChanges = async () => {
    setDataLoaded(false);
    setHasChanges(false);
    Blitzcrank.get(getTemplatePath(), (status, data) => {
      if (status === 200) {
        setPageBuild(data);
      }
      setDataLoaded(true);
    })
  }

  const revertToPublishedChanges = async () => {
    setDataLoaded(false);
    setHasChanges(false);
    Blitzcrank.get(getPublicPath(), (status, data) => {
      if (status === 200) {
        setPageBuild(data);
      }
      setDataLoaded(true);
    })
  }

  useEffect(() => {
    Blitzcrank.get(getTemplatePath(), (status, data) => {
      if (status === 200) {
        setPageBuild(data);
      }
      setDataLoaded(true);
    })
  }, [])

  const onPageTemplateChange = (val) => {
    let allFine = false;
    if (hasChanges) {
      allFine = window.confirm("Are you sure you want to change the template without saving your changes?")
    } else {
      allFine = true;
    }

    if (allFine) {
      setDataLoaded(false);
      setHasChanges(false);

      selectPageTemplate(val);

      Blitzcrank.get(getTemplatePath(val), (status, data) => {
        if (status !== 200) {
          data = [];
        }
        setPageBuild(data);
        setDataLoaded(true);
      })
    }
  }


  return (
    <>
      <Container fluid>
        <Prompt
          when={hasChanges}
          message={location =>
            `Are you sure you want to leave the page builder without saving your changes?`
          }
        />

        <Row>
          <Col lg="3" md="4" sm="12">
            <FormGroup>
              <Label for="templateSelect">Template</Label>
              <Input value={selectedPageTemplate} onChange={(e) => onPageTemplateChange(e.target.value)} type="select" name="select" id="templateSelect">
                {templates.map((template) => {
                  return <option value={template.id}>{template.title}</option>
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <hr/>

        <Row className="mb-2">
          <Col className="text-center" md="12">
            <h2>Page Builder</h2>
          </Col>
        </Row>

        <Row className="mb-7">
          <Col className="text-center show-desktop" md="12">
            <ButtonGroup>
              <Button icon={"undo"} color="default" outline onClick={revertChanges}>Revert Unsaved Changes</Button>
              <Button icon={"history"} color="primary" outline onClick={revertToPublishedChanges}>Revert to Latest Published Version </Button>
              <Button icon={"save"} color="primary" outline={!hasChanges} onClick={() => handleSave(getTemplatePath())}>Save</Button>
            </ButtonGroup>
            <Button  className="ml-2" icon={"upload"} color="primary" onClick={publish}>Publish</Button>
          </Col>
          <Col className="text-center show-mobile" md="12">
            <ButtonGroup>
              <Button icon={"undo"} color="default" outline onClick={revertChanges} />
              <Button icon={"history"} color="primary" outline onClick={revertToPublishedChanges}>Latest</Button>
              <Button icon={"save"} color="primary" outline={!hasChanges} onClick={() => handleSave(getTemplatePath())} />
            </ButtonGroup>
            <Button className="ml-2" icon={"upload"} color="primary" onClick={publish} />
          </Col>
        </Row>


        <Row>
          <Col md="12">
            {dataLoaded && <EditorJs
              onChange={() => setHasChanges(true)}
              enableReInitialize={false}
              ref={editorRef}
              data={pageBuild}
              tools={EDITOR_JS_TOOLS}
            />}
          </Col>
        </Row>


      </Container>
    </>
  );
};

export default PageBuilder;
