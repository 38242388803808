import { Button } from "components/Buttons/Button";
import React, { useEffect, useState } from "react";

import {
    Badge,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Row,
    Col
} from "reactstrap";
import useBlitzcrank from "requests/BlitzcrankWrapper";
import useStore from "store/store";
import Transformer from "transformers/Transformer";
import { DOWNLOAD_ORDER_FILES_PATH } from "variables/constants";
import { ORDER_STATUS } from "variables/constants";
import { ORDER_STATUS_TEXT } from "variables/constants";
import { ORDERS_PATH } from "variables/constants";
import { ORDERS_STATUS_UPDATE_PATH } from "variables/constants";
import fileDownload from "js-file-download";
import { Paginator } from "components/Pagination/Paginator";
import { TableSearch } from "components/Tables/TableSearch";
import { TableHeaderSortable } from "components/Tables/TableHeaderSortable";
import { useTranslation } from "react-i18next";
import usePricing from "utils/Pricing";
import { INPUT_CURRENCY } from "variables/constants";
import Utils from "utils/Utils";
import { useLocation } from "react-router-dom";
import { PAYMENT_TYPE_TEXT } from "variables/constants";
import { getCountryName } from "variables/countries";
import { SHIPPING_TYPE } from "variables/constants";
import { SHIPPING_TYPE_TEXT } from "variables/constants";
import { OrdersTableFilter } from "./OrdersTableFilter";

const OrderHistoryTableAdmin = (props) => {
    const Blitzcrank = useBlitzcrank();

    const { t } = useTranslation();
    const pricing = usePricing();
    const store = useStore();
    const location = useLocation();
    const [orders, setOrders] = useState([]);
    const [editOrderModal, setEditOrderModal] = useState(false);
    const [detailsOrderModal, setDetailsOrderModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [notifyCustomer, setNotifyCustomer] = useState(false);
    const [customerNotification, setCustomerNotification] = useState("");
    const [totalOrderRows, setTotalOrderRows] = useState(0);

    const dateOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" };

    const toggleEditOrderModal = () => {
        setEditOrderModal(!editOrderModal);
        if (editOrderModal === true) {
            setSelectedOrder(null);
            setNotifyCustomer(false);
            setCustomerNotification("");
        }
    }

    const toggleDetailsOrderModal = () => {
        setDetailsOrderModal(!detailsOrderModal);
        if (detailsOrderModal === false) setSelectedOrder(null);
    }

    const onOrderInputChange = (prop, val) => {
        const order = { ...selectedOrder };
        order[prop] = val;
        setSelectedOrder(order);
    }

    const saveOrder = async (order) => {
        const existingOrder = orders.find((o) => o.id === order.id);
        let statusChange = false;
        let orderDataChange = false;
        if (existingOrder.status !== order.status) statusChange = true;
        if (existingOrder.quantity !== order.quantity || existingOrder.totalPrice !== order.totalPrice) orderDataChange = true;
        let success = true;


        const promises = [];
        if (orderDataChange) promises.push(await Blitzcrank.update(ORDERS_PATH, {
            id: order.id,
            totalPrice: order.totalPrice,
            quantity: order.quantity,
            notifyCustomer: customerNotification ? true : notifyCustomer,
            comment: customerNotification
        }, (status, data) => {
            if (status !== 200) {
                success = false;
            }
        }));
        if (statusChange) promises.push(await Blitzcrank.update(ORDERS_STATUS_UPDATE_PATH, {
            id: order.id,
            status: order.status,
            comment: customerNotification,
            notifyCustomer: customerNotification ? true : notifyCustomer
        }, (status, data) => {
            if (status !== 200) {
                success = false;
            }
        }));

        await Promise.all(promises);

        if (success) {
            toggleEditOrderModal();
            setCustomerNotification("");
            Blitzcrank.get(ORDERS_PATH + Utils.getBasicTableParams(location), (status, data) => {
                if (status === 200) setOrders(Transformer.parse(data.rows));
            });
        }

    }

    const onEditOrder = (order) => {
        setSelectedOrder(order);
        setEditOrderModal(true);
    }

    const onCheckOrderDetails = (order) => {
        setSelectedOrder(order);
        setDetailsOrderModal(true);
    }

    useEffect(() => {
        if (store.currentUser) {
            Blitzcrank.get(ORDERS_PATH + Utils.getBasicTableParams(location), (status, data) => {
                if (status === 200) {
                    setOrders(Transformer.parse(data.rows));
                    setTotalOrderRows(data.totalRows);
                }
            });
        }
    }, [store.currentUser, location]);

    const downloadFiles = (orderId) => {
        Blitzcrank.get(DOWNLOAD_ORDER_FILES_PATH + "/" + orderId, (status, data) => {
            if (status === 200) {
                fileDownload(data, "order" + orderId + ".zip");
            }
        }, {
            responseType: 'arraybuffer'
        })
    }

    return (
        <>
            <Row>
                <Col lg="12">
                    {/* <TableSearch
                        placeholder={t("common.Search") + " #"}
                        endpoint={ORDERS_PATH}
                    /> */}
                    <OrdersTableFilter/>
                </Col>
            </Row>


            <Table responsive>
                <TableHeaderSortable
                    headers={[
                        {
                            text: "#",
                            id: "orderNumber",
                            sortable: true
                        },
                        {
                            text: t("common.PlacedOn"),
                            id: "placedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.OrderAcceptedOn"),
                            id: "acceptedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.OrderCompletedOn"),
                            id: "completedOnDate",
                            sortable: true
                        },
                        {
                            text: t("common.Status")
                        },
                        {
                            text: t("common.TotalPrice"),
                            id: "totalPrice",
                            sortable: true
                        },
                        {
                            text: t("common.Author")
                        },
                        {
                            text: ""
                        }
                    ]}
                />

                <tbody>
                    {orders.map((order) => {
                        let statusDotClassName = "bg-warning";
                        switch (order.status) {
                            case ORDER_STATUS.PENDING:
                                statusDotClassName = "bg-warning";
                                break;
                            case ORDER_STATUS.ACCEPTED:
                                statusDotClassName = "bg-info";
                                break;
                            case ORDER_STATUS.COMPLETED:
                                statusDotClassName = "bg-success";
                                break;
                            case ORDER_STATUS.CANCELLED:
                                statusDotClassName = "bg-danger";
                                break;
                            default:
                                statusDotClassName = "bg-warning";
                        }
                        return <tr>
                            <td>{order.orderNumber}</td>
                            <td>{new Date(order.placedOnDate).toLocaleString("en-GB",
                                dateOptions)}</td>
                            <td>{order.acceptedOnDate ? new Date(order.acceptedOnDate).toLocaleDateString(
                                "en-GB",
                                dateOptions
                            ) : t("common.OrderNotAccepted")}</td>
                            <td>{order.completedOnDate ? new Date(order.completedOnDate).toLocaleString("en-GB",
                                dateOptions) : t("common.OrderNotFinished")}</td>
                            <td>
                                <Badge color="" className="badge-dot mr-4">
                                    <i className={statusDotClassName} />
                                    {t(ORDER_STATUS_TEXT[order.status])}
                                </Badge>
                            </td>
                            <td>{pricing.format(order.totalPrice)}</td>
                            <td>{order.userFirstName} {order.userLastName}</td>
                            <td className="text-right">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        className="btn-icon-only text-light"
                                        href="#pbl"
                                        role="button"
                                        size="sm"
                                        color=""
                                        onClick={e => e.preventDefault()}
                                    >
                                        <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                            href="#pbl"
                                            onClick={() => onCheckOrderDetails(order)}
                                        >
                                            {t("common.Details")}
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pbl"
                                            onClick={() => onEditOrder(order)}
                                        >
                                            {t("common.Edit")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    })}

                </tbody>
            </Table>

            <Row>
                <Col>
                    <Paginator
                        totalRows={totalOrderRows} rightAligned />
                </Col>
            </Row>



            {selectedOrder && <Modal isOpen={editOrderModal} toggle={toggleEditOrderModal}>
                <ModalHeader
                    toggle={toggleEditOrderModal}>{t("common.EditOrder")} #{selectedOrder.orderNumber}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>{t("common.Status")}</Label>
                        <Input value={selectedOrder.status}
                            onChange={(e) => onOrderInputChange("status", e.target.value)} type="select"
                            name="SelectOrderStatus">
                            {Object.keys(ORDER_STATUS_TEXT).map((statusNumber) => {
                                return <option value={statusNumber}>{t(ORDER_STATUS_TEXT[statusNumber])}</option>
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t("common.TotalPrice")} ({INPUT_CURRENCY})</Label>
                        <Input value={selectedOrder.totalPrice}
                            onChange={(e) => onOrderInputChange("totalPrice", e.target.value)} type="text"
                            name="TotalPrice" />
                    </FormGroup>
                    <FormGroup>
                        <Label>{t("common.Quantity")}</Label>
                        <Input value={selectedOrder.quantity}
                            onChange={(e) => onOrderInputChange("quantity", e.target.value)} type="text"
                            name="Quantity" />
                    </FormGroup>
                    {selectedOrder.status != ORDER_STATUS.CANCELLED && <FormGroup check>
                        <Label check>
                            <Input onChange={(e) => setNotifyCustomer(e.target.checked)} checked={notifyCustomer}
                                type="checkbox" />{' '}
                            {t("common.NotifyCustomer")}
                        </Label>
                    </FormGroup>}
                    {notifyCustomer || selectedOrder.status == ORDER_STATUS.CANCELLED ? <FormGroup className="mt-2">
                        <Label>{t("common.Comment")} {selectedOrder.status == ORDER_STATUS.CANCELLED && "(required)"}</Label>
                        <Input onChange={(e) => setCustomerNotification(e.target.value)} value={customerNotification}
                            type="textarea" name="Comment" />
                    </FormGroup> : null}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={(selectedOrder.status == ORDER_STATUS.CANCELLED && !customerNotification)}
                        color="primary" onClick={() => saveOrder(selectedOrder)}>{t("common.Save")}</Button>{' '}
                    <Button color="secondary" onClick={toggleEditOrderModal}>{t("common.Cancel")}</Button>
                </ModalFooter>
            </Modal>}

            {selectedOrder && <Modal isOpen={detailsOrderModal} toggle={toggleDetailsOrderModal}>
                <ModalHeader
                    toggle={toggleDetailsOrderModal}>{t("common.Order")} #{selectedOrder.orderNumber}</ModalHeader>
                <ModalBody>
                    <span className="text-muted">{t("common.PlacedOn")}</span><br />
                    <span>{new Date(selectedOrder.placedOnDate).toLocaleString()}</span><br /><br />

                    <span className="text-muted">{t("common.DeliveryMethod")}</span><br />
                    <span>{t(SHIPPING_TYPE_TEXT[selectedOrder.shippingType])}</span><br /><br />

                    <span className="text-muted">{t("common.ShippingAddress")}</span><br />

                    {selectedOrder.shippingType === SHIPPING_TYPE.PICKUP && <>
                        <span>{t("personal.address")}</span><br /><br />
                    </>}

                    {selectedOrder.shippingType === SHIPPING_TYPE.DELIVERY && <>
                        <span>{selectedOrder.sa_addressLine}</span><br />
                        <span>{selectedOrder.sa_city}, {selectedOrder.sa_postalCode}, {getCountryName(selectedOrder.sa_country)}</span><br />
                        <span>{selectedOrder.sa_phoneNumber}</span><br /><br />
                    </>}

                    <span className="text-muted">{t("common.Status")}</span><br />
                    <span>{t(ORDER_STATUS_TEXT[selectedOrder.status])}</span><br /><br />
                    <span className="text-muted">{t("common.Product")}</span><br />
                    <span>{selectedOrder.quantity}x {selectedOrder.productTitle}</span><br /><br />
                    <span>{pricing.format(selectedOrder.totalPrice - selectedOrder.taxes)}</span><br /><br />

                    <span className="text-muted">{t("common.OrderHistoryTaxes")}</span><br />
                    <span>{pricing.format(selectedOrder.taxes)}</span><br /><br />

                    <span className="text-muted">{t("common.TotalPrice")}</span><br />
                    <span>{pricing.format(selectedOrder.totalPrice)}</span><br /><br />

                    <span className="text-muted">{t("common.Author")}</span><br />
                    <span>{selectedOrder.userFirstName + " " + selectedOrder.userLastName}</span><br />
                    <span>{selectedOrder.userEmail}</span><br /><br />

                    <span className="text-muted">{t("common.PaymentType")}</span><br />
                    <span>{t(PAYMENT_TYPE_TEXT[selectedOrder.paymentType])}</span><br /><br />

                    <span className="text-muted">{t("common.Comment")}</span><br />
                    <span>{selectedOrder.comment || t("common.None")}</span>

                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => {
                        downloadFiles(selectedOrder.orderNumber);
                        setDetailsOrderModal(false);
                    }}>{t("common.DownloadFiles")}</Button>
                    <Button color="primary" onClick={() => {
                        setDetailsOrderModal(false);
                        setEditOrderModal(true);
                    }}>{t("common.Edit")}</Button>{' '}
                    <Button color="secondary" onClick={toggleDetailsOrderModal}>{t("common.Cancel")}</Button>
                </ModalFooter>
            </Modal>}
        </>
    );
};

export default OrderHistoryTableAdmin;
