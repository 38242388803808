import OrderHistoryTableAdmin from "components/Orders/OrderHistoryTableAdmin";
import React from "react";

import {
    Container
} from "reactstrap";

const OrdersMaintenance = (props) => {
  return (
    <>
      <Container fluid>
        <OrderHistoryTableAdmin/>
      </Container>
    </>
  );
};

export default OrdersMaintenance;
